// calls all apis for ya

import Axios from 'axios';
import {
  apiBaseUrl,
  CONTEXT,
  apiFileUploadBaseUrl,
  authBaseUrl,
  demoTenantBaseUrl,
  demoTenantIds
} from '../constants/configuration';

// const TYPE_JSON = 'application/json';
const TYPE_JSON = 'application/json;charset=UTF-8';

export const buildAPIInstance = ({ auth = false, multipart = false, contenttype = TYPE_JSON }) => {

  let demoTenant = false;
  let preSignInData = JSON.parse(localStorage.getItem("PreSignInState"));
  if (preSignInData && preSignInData.tenantId) {
    if (demoTenantIds.includes(preSignInData.tenantId)) {
      demoTenant = true
    }
  }

  // wait for valid base url
  const objAxios = Axios.create({
    baseURL: multipart ? apiFileUploadBaseUrl : auth ? authBaseUrl : demoTenant ? demoTenantBaseUrl : apiBaseUrl,
    // baseURL: multipart ? 'http://localhost:7100/atd' : auth ? authBaseUrl : apiBaseUrl,
    // baseURL: apiBaseUrl,
    headers: {
      domain: window.location.origin,
      // domain: 'https://stageatdv2.torcai.com',
      // domain : 'https://integration.torcai.com/atd',
      // domain: 'https://testbench.torcai.com/atd',
      // domain: "https://ionv2.torcai.com",

      common: {
        Accept: TYPE_JSON,
        'Content-Type': contenttype
      },
      post: {
        'Content-Type': TYPE_JSON
      }
    },
    transformResponse: [function (data) {

      // if (data) {
      //   try {
      //     const newdata = JSON.parse(data);
      //     if (newdata ? (newdata.responseCode && newdata.responseCode.desc === 'Not authorised') : (newdata.responeCode && newdata.responeCode.msg === 'Invalid User Token')) {
      //       localStorage.clear();
      //       window.location.href = `${CONTEXT}`;
      //       throw new Error('token expired');
      //     }
      //   } catch (err) {
      //     return data;
      //   }
      // }
      if (data) {
        try {
          const newdata = JSON.parse(data);
          if (newdata ? (newdata.responseCode && (newdata.responseCode.desc === 'Not authorised' || newdata.responseCode.msg === 'Invalid User Token')) || (newdata.responeCode && (newdata.responeCode.desc === 'Not authorised' || newdata.responeCode.msg === 'Invalid User Token')) : (newdata.responeCode && newdata.responeCode.msg === 'Invalid User Token')) {
            localStorage.clear();
            window.location.href = `${CONTEXT}`;
            throw new Error('token expired');
          }
        } catch (err) {
          return data;
        }
      }
      return JSON.parse(data);
    }],


  });

  return objAxios;
};

const client = (params = {}) => buildAPIInstance(params);

export default client;