import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { get, isEmpty, uniq } from 'lodash';
import asyncRoute from '../helpers/hoc/asyncRoute';
import { dispatchAuthenticationDataAction } from '../actions';
import routes from '../routes';
import { getRoleAccessPermissions, getConfigurationDataAction, getCountryAction, getPincodeAllAction, getStateAllAction, getCityAllAction, logoutAction } from '../actions';

import { PrivateRoute } from '../utils/route-auth';
import { MODULE_PERMISSIONS } from '../constants/permission';

// compnennts import

const Header = asyncRoute(() => import('../components/Header'));
const SideNav = asyncRoute(() => import('../components/SideNav'));
const Footer = asyncRoute(() => import('../components/Footer'));
const Spinner = asyncRoute(() => import('../components/Spinner'));

// container import

const PreSignIn = asyncRoute(() => import('./Authentication/PreSignIn'));
const SignIn = asyncRoute(() => import('./Authentication/SignIn'));
const Signup = asyncRoute(() => import('./Authentication/Signup'));
const ForgotPassword = asyncRoute(() => import('./Authentication/ForgotPassword'));
const ResetPassword = asyncRoute(() => import('./Authentication/ResetPassword'));
const Admin = asyncRoute(() => import('./Admin'));
const ListMediaPlan = asyncRoute(() => import('./MEDIAPLAN'));
const Campaign = asyncRoute(() => import('./AtdCampaign'));
const Dashboard = asyncRoute(() => import('./AtdDashboard'));
const NotAuthorize = asyncRoute(() => import('./NotAuthorize'));
const ListRO = asyncRoute(() => import('./RO'))
const OptimizerScreen = asyncRoute(() => import('./OPTIMIZER'))
const unmappedCampaignScreen = asyncRoute(() => import('./UNMAPPEDCAMPAIGN'))
const Goals = asyncRoute(()=> import('./Goals'));
const MarginScreen = asyncRoute(() => import('./Margin'))
const AdminMargin = asyncRoute(() => import('./AdminMargin'))
// const DataConfig = asyncRoute(() => import('./DataConfig'))
// const Payment = asyncRoute(() => import('./Payment'))
const Audience = asyncRoute(() => import("./Audience"));
const DataConfig = asyncRoute(() => import('./DataConfig'))

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false
        };
    }

    async componentWillMount() {
        let signInData = JSON.parse(localStorage.getItem('AuthData'));
        let preSignInData = JSON.parse(localStorage.getItem('PreSignInData'));
        let preSignInState = JSON.parse(localStorage.getItem('PreSignInState'));

        if (isEmpty(signInData) || isEmpty(preSignInData) || isEmpty(preSignInState)) {
            const AuthData = signInData || {};
            const { authToken } = AuthData;
            const { logoutAction, preSignInState, history, match, location } = this.props;
            if (location.pathname == `${match.path}/${routes.resetPassword}`) {
                let search = location.search;
                const params = new URLSearchParams(search);
                const rp = params.get('rp');
                // const uid = params.get('uid');
                // history.push(`${match.path}/${routes.resetPassword}?rp=${rp}&uid=${uid}`);
                history.push(`${match.path}/${routes.resetPassword}?rp=${rp}`);
                return;
            }
            else {
                if (!(isEmpty(signInData) && isEmpty(preSignInData) && isEmpty(preSignInState))) {
                    logoutAction(preSignInState, authToken).then(() => {
                        history.push(`${match.path}/${routes.preSignIn}`);
                    });
                } else {
                    // console.log("UUUUUUUUUUUUUUUUUUUUUUUUUUUUU");
                    history.push(`${match.path}/${routes.preSignIn}`);
                }
                return;
            }
        }
        const { getRoleAccessPermissions, dispatchAuthenticationDataAction, getConfigurationDataAction, getCountryAction, getPincodeAllAction, getStateAllAction, getCityAllAction } = this.props;
        if (!isEmpty(signInData) || !isEmpty(preSignInData) || !isEmpty(preSignInState)) {

            getRoleAccessPermissions({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            dispatchAuthenticationDataAction({ preSignInState, signInData, preSignInData });
            getConfigurationDataAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });

            // getCountryAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            // getStateAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            // getCityAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            // getPincodeAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
        }
    }

    toggleSidebar = (collapseSideBar) => {
        // console.log('eqweqweqwe');
        // const { collapse } = this.state
        this.setState({ collapse: !collapseSideBar })
    }

    render() {
        let isAuthenticated = JSON.parse(localStorage.getItem('AuthData'));
        const { showSpinner, match } = this.props;
        const { collapse } = this.state;
        // console.log(`${match.path}/${routes.containers.dashboard}`," mainRoutes");

        return (
            <>
                {showSpinner && <Spinner />}
                <Switch>
                    <Route exact path={`${match.path}`} render={() => <Redirect to={`${match.path}/${routes.preSignIn}`} />} />
                    <Route exact path={`${match.path}/${routes.preSignIn}`} component={PreSignIn} />
                    <Route exact path={`${match.path}/${routes.signIn}`} component={SignIn} />
                    {/* <Route exact path={`${match.path}/${routes.signup}`} component={Signup} /> */}
                    <Route exact path={`${match.path}/${routes.forgotPassword}`} component={ForgotPassword} />
                    <Route exact path={`${match.path}/${routes.resetPassword}`} component={ResetPassword} />
                    <Route exact path={`${match.path}/${routes.notAuthorize}`} component={NotAuthorize} />


                    {
                        !isEmpty(isAuthenticated) &&
                        <>
                            <div className={`${collapse ? 'collapseWrapper' : ''}`}>
                                <div className={`${collapse ? 'overLayStyle' : ''}`}></div>
                                <Header isCollapse={collapse} toggleSidebar={this.toggleSidebar} />
                                <SideNav isCollapse={collapse} toggleSidebar={this.toggleSidebar} />
                                {/* {/ <Route exact path={`${match.path}`} render={() => <Redirect to={`${match.path}/${routes.containers.dashboard}`} />} /> /}
                                {/ <Route path={`${match.path}/${routes.containers.ion}`} component={ION} /> /}
                                {/ <Route path={`${match.path}/${routes.containers.io}`} component={IO} /> /}
                                {/ <Route path={`${match.path}/${routes.containers.admin}`} component={Admin} /> /}
                                {/ <Route path={`${match.path}/${routes.containers.campaign}`} component={Campaign} /> /}
                                {/ <Route path={`${match.path}/${routes.containers.creative}`} component={Creative} /> /}
                                {/ <Route path={`${match.path}/${routes.containers.dashboard}`} component={Dashboard} /> /} */}
                                <PrivateRoute path={`${match.path}/${routes.containers.dashboard}`} component={Dashboard}
                                    permissions={MODULE_PERMISSIONS.DASHBOARD}
                                    defaultRoute={'dashboard'} />

                                <PrivateRoute path={`${match.path}/${routes.containers.admin}`} component={Admin}
                                    permissions={MODULE_PERMISSIONS.ADMIN}
                                    defaultRoute={'admin'} />


                                <PrivateRoute path={`${match.path}/${routes.containers.campaignList}`} component={Campaign}
                                    permissions={MODULE_PERMISSIONS.CAMPAIGN}
                                    defaultRoute={'campaign'} />


                                <PrivateRoute path={`${match.path}/${routes.containers.mediaPlan}`} component={ListMediaPlan}
                                    permissions={MODULE_PERMISSIONS.MEDIAPLAN}
                                    defaultRoute={'mediaPlan'} />

                                <Route path={`${match.path}/${routes.containers.goals}`} component={Goals}
                                    // permissions={MODULE_PERMISSIONS.UNMAPPEDCAMPAIGN}
                                    // defaultRoute={'unmapped'} 
                                    />

                                {/* <PrivateRoute path={`${match.path}/${routes.containers.ro}`} component={ListRO}
                                    permissions={MODULE_PERMISSIONS.RO}
                                    defaultRoute={'ro'} /> */}

                                <PrivateRoute path={`${match.path}/${routes.containers.optimizer}`} component={OptimizerScreen}
                                    permissions={MODULE_PERMISSIONS.OPTIMIZER}
                                    defaultRoute={'optimizer'} />

                                {/* <PrivateRoute path={`${match.path}/${routes.containers.unmappedCampaign}`} component={unmappedCampaignScreen}
                                    permissions={MODULE_PERMISSIONS.UNMAPPEDCAMPAIGN}
                                    defaultRoute={'unmapped'} /> */}

                                <PrivateRoute path={`${match.path}/${routes.containers.margin}`} component={MarginScreen}
                                    permissions={MODULE_PERMISSIONS.MARGIN}
                                    defaultRoute={'margin'} />

                                <PrivateRoute path={`${match.path}/${routes.containers.adminMargin}`} component={AdminMargin}
                                    permissions={MODULE_PERMISSIONS.ADMINMARGIN}
                                    defaultRoute={'adminMargin'} />


                                {/* <Route path={`${match.path}/${routes.containers.dataConfig}`} component={DataConfig}
                                // permissions={MODULE_PERMISSIONS.ADMINMARGIN}
                                defaultRoute={'DataConfig'} /> */}

                            {/* <Route path={`${match.path}/${routes.containers.payment}`} component={Payment}
                                // permissions={MODULE_PERMISSIONS.PAYMENT}
                                defaultRoute={'payment'} /> */}

                                <PrivateRoute path={`${match.path}/${routes.containers.audience}`} component={Audience}
                                    permissions={MODULE_PERMISSIONS.AUDIENCE}
                                    defaultRoute={'audience'} />
                                <PrivateRoute path={`${match.path}/${routes.containers.dataConfig}`} component={DataConfig}
                                    permissions={MODULE_PERMISSIONS.DATACONFIG}
                                    defaultRoute={'DataConfig'} />
                                <Footer />
                            </div>
                        </>
                    }
                </Switch>

            </>
        );
    }
}

export default connect(
    state => ({
        showSpinner: get(state, 'app.showSpinner', false),
        configurationList: get(state, 'app.configurationData.responseList', []),
        tenantId: get(state, 'app.preSignInState.tenantId', ''),
        userId: get(state, 'app.preSignInData.userId', ''),
        authToken: get(state, 'app.signInData.authToken', '')
    }),
    {
        logoutAction,
        getConfigurationDataAction,
        dispatchAuthenticationDataAction,
        getCountryAction,
        getStateAllAction,
        getCityAllAction,
        getRoleAccessPermissions,
        getPincodeAllAction
    }
)(App);