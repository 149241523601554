export const apiBaseUrl = window.location.protocol === 'https:' ? 'https://integration.torcai.com' : 'https://integration.torcai.com';
// export const apiFileUploadBaseUrl = 'https://integration.torcai.com/atd';
// export const apiFileUploadBaseUrl = 'http://localhost:7100/atd';
// export const authEndPoints = 'http://103.144.119.160:7008'
export const authBaseUrl = 'https://integration.torcai.com'
export const apiFileUploadBaseUrl = 'https://integration.torcai.com/atd';
export const demoTenantBaseUrl = "https://atdtestdemo.torcai.com";
export const version = '1.0.0';
export const domain = 'torcai.in';
export const constants = {};
export const tenantIdsToShowDemoData = [];
export const endPoints = {
  atd: '/report-atd',
  atdDashboard : '/report-atd/dashboard',
  auth: '/auth/v3',
  geo: '/tai/geo/torcai',
  bidder: '/bidder/report',
  io: '/io',
  campaign: '/campaign',
  campaignPmp: '/campaign-pmp',
  creative: '/creative',
  medialist: '/medialist',
  data: '/data',
  ionReport: '/ion/report',
  adUnitBulk: '/adunitbulk',
  adUnitMap: '/adunitmap', 
  mediation: '/mediation',
  pmp: '/pmp',
  adgroup: '/adgroup',
  adunit: '/adunit',
  payment: "/payment",
  fb: '/fb'
}
export const CONTEXT = '/atd';
export const torcaiTracking = apiBaseUrl + '/audtrack/postback/track?'
export const tomcatApiUrl = apiBaseUrl.replace(window.location.protocol, "")  + '/content/dmp';
export const iFrameApiUrl = apiBaseUrl + '/dmp/jsv2';
export const imageApiUrl = apiBaseUrl + '/audtrack/param/track?';
export const imageUrl = apiBaseUrl + '/var/www/html/report-atd';
