// app level reducer

import {
  BEGIN_AJAX_CALL,
  END_AJAX_CALL,
  PRE_SIGN_IN_SUCCESS,
  SIGN_IN_SUCCESS,
  GET_ADD_UNIT_LIST,
  GET_ADD_UNIT_GROUP_LIST,
  GET_PMP_LIST,
  GET_TAGLD_MAPPING_LIST,
  GET_TAGLD_MAPPING_ADUNIT_ALL_LIST,
  GET_TAGLD_MAPPING_BULK_LIST,
  DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
  GET_CONFIGURATION_DATA,
  GET_FILTER_LIST,
  GET_ION_FILTER_LIST,
  GET_MEDIA_REPORT,
  GET_ION_EXCHANGE_REPORT,
  GET_QPS_REPORT,
  GET_REVENUE_REPORT,
  GET_BIDDER_REPORT,
  GET_MEDIA_PLANNING_REPORT,
  GET_CAMPAIGN_PERFORMANCE_REPORT,
  GET_CAMPAIGN_LIST_ALL_REPORT,
  GET_CAMPAIGN_CATEGORY_TYPE_LIST,
  GET_IO_LIST_ALL_REPORT,
  GET_COUNTRY_LIST,
  GET_CITY_LIST_ALL,
  GET_STATE_LIST_ALL,
  GET_STATE_LIST,
  GET_CITY_LIST,
  GET_CAMPAIGN_SUB_CATEGORY,
  GET_ADVERTISER_CREATIVE_GROUP,
  GET_IO_ADVERTISER_LIST_REPORT,
  GET_TENANT_LIST_ALL,
  GET_SUPPLY_PARTNER_LIST_ALL,
  GET_DEMAND_PARTNER_LIST_ALL,
  GET_TENANT_DEMAND_PARTNER_LIST,
  GET_ADMIN_SUPPLY_PARTNER_LIST_ALL,
  GET_TENANT_SUPPLY_PARTNER_LIST_ALL,
  GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL,
  GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL,
  GET_LIST_FEATURE_ALL,
  GET_FEATURE_LIST_ALL,
  GET_MEDIATION_LIST,
  GET_MEDIATION_AD_GROUP_LIST,
  GET_PRIVATE_MARKET_PLACE_LIST,
  GET_EXCHANGE_LIST_REPORT,
  GET_AGREEMENT_LIST_ALL,
  GET_AGREEMENT_CONNECTION_LIST,
  GET_ADMIN_USER_LIST_ALL,
  GET_PLATFORM_LIST_ALL,
  GET_ADMIN_ROLES_LIST_ALL,
  GET_DEMAND_PARTNER_NAME_LIST_ALL,
  GET_USER_PERMISSION,
  GET_APR_LIST_ALL,
  GET_SEGMENT_LIST_ALL,
  GET_HIERARCHY_LIST_ALL,
  GET_DATA_CAPTURE_LIST_ALL,
  GET_AUDIENCE_HIERARCHY_LIST_ALL,
  GET_PINCODE_LIST,
  GET_CAMPAIGN_SITE_LIST_ALL,
  GET_CAMPAIGN_PERFORMANCE_REPORT_TREND,
  GET_MEDIA_PLANNING_REPORT_TREND,
  GET_PINCODE_LIST_ALL,
  GET_CAMPAIGN_PIXEL_LIST,
  GET_DEMAND_PARTNER_SEAT_LIST_ALL,
  GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST,
  GET_SAVE_REPORT,
  GET_MEDIA_SAVE_REPORT,
  GET_GENERATE_REPORT,

  GET_REFRESH_REPORT,
  GET_ACCOUNTS_LIST,
  GET_USER_REACH_REPORT,
  GET_ROADBLOCK_LIST,
  // GET_CAMPAIGN_PERFORMANCE_REPORT,
  POST_CAMPAIGN_PERFORMACE_REPORT,
  POST_GEO_REPORT,
  POST_CREATIVE_PERFORMANCE_REPORT,
  POST_AGE_GENDER_REPORT,
  POST_CREATIVE_DETAILS,
  CREATE_ADVERTISER,
  ADVERTISER_LIST,
  GET_MEDIA_PLAN_LIST,
  GET_OPTIMIZER_LIST,
  GET_AGE_LIST,
  GET_CATEGORY_LIST,
  GET_SUBCATEGORY_LIST,
  POST_MEDIA_PLAN_CREATION,
  GET_PRIMARY_GOAL,
  GET_SECONDARY_GOAL,
  POST_GENDER_REPORT,
  POST_AGE_REPORT,
  GET_UNMAPPED_CAMPAIGN_COUNT,
  GET_UNMAPPED_CAMPAIGN_LIST,
  RECO_IMPLEMENTATION,
  GET_ADVERTISER_LIST,
  POST_CAMPAIGN_PERFORMACE_TOTAL_REPORT,
  POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT,
  POST_CHANNEL_SPEND,
  POST_WORLD_MAP_DATA,
  GET_CURRENCY_LIST
} from '../constants/actionType';

const app = (state = {}, action) => {
  switch (action.type) {
    case BEGIN_AJAX_CALL:
    case END_AJAX_CALL:
      const { showSpinner } = action;
      return {
        ...state,
        showSpinner
      };
    case GET_CONFIGURATION_DATA:
      const {
        configurationData
      } = action;
      return {
        ...state,
        configurationData,
      };
    case GET_UNMAPPED_CAMPAIGN_COUNT:
        const { countUnmapped } = action
       return{
        ...state,
        countUnmapped
        };
    case GET_FILTER_LIST:
      const {
        filterList
      } = action;
      return {
        ...state,
        filterList,
      };
    case POST_CREATIVE_DETAILS:
      const { creativeDetailsList } = action;
      return {
        ...state,
        creativeDetailsList,
      }
    case GET_ION_FILTER_LIST:
      const {
        ionFilterList
      } = action;
      return {
        ...state,
        ionFilterList,
      };

    case PRE_SIGN_IN_SUCCESS:
      const {
        preSignInData
      } = action;
      return {
        ...state,
        preSignInData,
      };
    case SIGN_IN_SUCCESS:
      const {
        signInData
      } = action;
      return {
        ...state,
        signInData,
      };
    case DISPATCH_PRE_SIGNIN_STATE_SUCCESS:
      const { preSignInState } = action;
      return {
        ...state,
        preSignInState,
      };
    case GET_ADD_UNIT_LIST:
      const { adUnitList } = action;
      return {
        ...state,
        adUnitList,
      };
    case GET_ADD_UNIT_GROUP_LIST:
      const { adUnitGroupList } = action;
      return {
        ...state,
        adUnitGroupList,
      };
    case GET_PMP_LIST:
      const { pmpList } = action;
      return {
        ...state,
        pmpList,
      };
    case GET_TAGLD_MAPPING_LIST:
      const { tagldMappingList } = action;
      return {
        ...state,
        tagldMappingList,
      };
    case GET_TAGLD_MAPPING_ADUNIT_ALL_LIST:
      const { tagldMappingListadunitAll } = action;
      return {
        ...state,
        tagldMappingListadunitAll,
      };
    case GET_TAGLD_MAPPING_BULK_LIST:
      const { tagldMappingBulkList } = action;
      return {
        ...state,
        tagldMappingBulkList,
      };
    case GET_MEDIA_REPORT:
      const { mediaReport } = action;
      return {
        ...state,
        mediaReport,
      };
    case GET_ION_EXCHANGE_REPORT:
      const { ionExchange } = action;
      return {
        ...state,
        ionExchange,
      };

    case GET_QPS_REPORT:
      const { qpsReport } = action;
      return {
        ...state,
        qpsReport,
      };
    case GET_REVENUE_REPORT:
      const { revenueReport } = action;
      return {
        ...state,
        revenueReport,
      };
    case GET_BIDDER_REPORT:
      const { bidderReport } = action;
      return {
        ...state,
        bidderReport,
      };
    case GET_MEDIA_PLANNING_REPORT:
      const { mediaPlanningReport } = action;
      return {
        ...state,
        mediaPlanningReport,
      };
    case GET_CAMPAIGN_PERFORMANCE_REPORT:
      const { campaignPerformanceReport } = action;
      return {
        ...state,
        campaignPerformanceReport,
      };
    case GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST:
      const { campaignPerformanceReportFilterList } = action;
      return {
        ...state,
        campaignPerformanceReportFilterList,
      };
    case GET_CAMPAIGN_LIST_ALL_REPORT:
      const { campaignListAllReport } = action;
      return {
        ...state,
        campaignListAllReport,
      };
    case GET_IO_LIST_ALL_REPORT:
      const { ioListAllReport } = action;
      return {
        ...state,
        ioListAllReport,
      };
    case GET_COUNTRY_LIST:
      const { getCountryList } = action;
      return {
        ...state,
        getCountryList,
      };
    case GET_STATE_LIST:
      const { getStateList } = action;
      return {
        ...state,
        getStateList,
      };
    case GET_CITY_LIST:
      const { getCityList } = action;
      return {
        ...state,
        getCityList,
      };
    case GET_USER_REACH_REPORT:
      const { userReachReport } = action;
      return {
        ...state,
        userReachReport,
      }
    case GET_STATE_LIST_ALL:
      const { getStateAllList, getStateAllFilterList } = action;
      return {
        ...state,
        getStateAllList,
        getStateAllFilterList
      };
    case GET_CITY_LIST_ALL:
      const { getCityAllList, getCityAllFilterList } = action;
      return {
        ...state,
        getCityAllList,
        getCityAllFilterList
      };
    case GET_CAMPAIGN_SUB_CATEGORY:
      const { getCampaignSubCategoryReport } = action;
      return {
        ...state,
        getCampaignSubCategoryReport,
      };
    case GET_ADVERTISER_CREATIVE_GROUP:
      const { getAdvertiserCreativeGroup } = action;
      return {
        ...state,
        getAdvertiserCreativeGroup,
      };
    case GET_IO_ADVERTISER_LIST_REPORT:
      const { ioAdvertisersReport } = action;
      return {
        ...state,
        ioAdvertisersReport,
      };
    case GET_EXCHANGE_LIST_REPORT:
      const { exchangeReport } = action;
      return {
        ...state,
        exchangeReport,
      };
    case GET_TENANT_LIST_ALL:
      const { tenantListAll } = action;
      return {
        ...state,
        tenantListAll,
      };
    case GET_SUPPLY_PARTNER_LIST_ALL:
      const { supplyPartnerListAll } = action;
      return {
        ...state,
        supplyPartnerListAll,
      };
    case GET_DEMAND_PARTNER_SEAT_LIST_ALL:
      const { demandpartnerSeatList } = action;
      return {
        ...state,
        demandpartnerSeatList,
      };
    case GET_DEMAND_PARTNER_LIST_ALL:
      const { demandpartnerList } = action;
      return {
        ...state,
        demandpartnerList,
      };
    case GET_TENANT_DEMAND_PARTNER_LIST:
      const { tenantDemandpartnerList } = action;
      return {
        ...state,
        tenantDemandpartnerList,
      };
    case GET_ADMIN_SUPPLY_PARTNER_LIST_ALL:
      const { adminSupplypartnerData } = action;
      return {
        ...state,
        adminSupplypartnerData,
      };
    case GET_TENANT_SUPPLY_PARTNER_LIST_ALL:
      const { adminTenantSupplypartnerData } = action;
      return {
        ...state,
        adminTenantSupplypartnerData,
      };
    case GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL:
      const { ionSupplypathData } = action;
      return {
        ...state,
        ionSupplypathData,
      };
    case GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL:
      const { pvtSupplypathData } = action;
      return {
        ...state,
        pvtSupplypathData,
      };
    case GET_CAMPAIGN_CATEGORY_TYPE_LIST:
      const { campaignCategoryTypeList } = action;
      return {
        ...state,
        campaignCategoryTypeList,
      };
    case GET_LIST_FEATURE_ALL:
      const { listFeatureAll, responseType } = action;
      return {
        ...state,
        [responseType]: listFeatureAll,
      };
    case GET_FEATURE_LIST_ALL:
      const { featurelist } = action;
      return {
        ...state,
        featurelist,
      };
    case GET_MEDIATION_LIST:
      const { mediationList } = action;
      return {
        ...state,
        mediationList,
      };
    case GET_MEDIATION_AD_GROUP_LIST:
      const { mediationAdGroupList } = action;
      return {
        ...state,
        mediationAdGroupList,
      };
    case GET_PRIVATE_MARKET_PLACE_LIST:
      const { privateMarketPlaceList } = action;
      return {
        ...state,
        privateMarketPlaceList,
      };
    case GET_AGREEMENT_LIST_ALL:
      const { adminAgreementData } = action;
      return {
        ...state,
        adminAgreementData,
      };
    case GET_AGREEMENT_CONNECTION_LIST:
      const { connectionAgreementData } = action;
      return {
        ...state,
        connectionAgreementData,
      };
    case GET_ADMIN_USER_LIST_ALL:
      const { adminUserData } = action;
      return {
        ...state,
        adminUserData,
      };
    case GET_PLATFORM_LIST_ALL:
      const { platformListAll } = action;
      return {
        ...state,
        platformListAll,
      };
    case GET_ADMIN_ROLES_LIST_ALL:
      const { adminRolesData } = action;
      return {
        ...state,
        adminRolesData,
      };
    case GET_DEMAND_PARTNER_NAME_LIST_ALL:
      const { demandPartnerNameList } = action;
      return {
        ...state,
        demandPartnerNameList,
      };
    case GET_USER_PERMISSION:
      const { getUserPermissionList, defaultRoute } = action;
      return {
        ...state,
        getUserPermissionList,
        defaultRoute
      };
    case GET_ADVERTISER_LIST: 
    const {advertiserList} = action;
      return {
        ...state,
        advertiserList
      }
    case GET_APR_LIST_ALL:
      const { aprListAll } = action;
      return {
        ...state,
        aprListAll,
      };
    case GET_SEGMENT_LIST_ALL:
      const { getSegmentList } = action;
      return {
        ...state,
        getSegmentList,
      };
    case GET_HIERARCHY_LIST_ALL:
      const { getHierarchyList } = action;
      return {
        ...state,
        getHierarchyList,
      };
    case GET_DATA_CAPTURE_LIST_ALL:
      const { getDataCaptureList } = action;
      return {
        ...state,
        getDataCaptureList,
      };
    case GET_AUDIENCE_HIERARCHY_LIST_ALL:
      const { audienceHierarchyList } = action;
      return {
        ...state,
        audienceHierarchyList,
      };
    case GET_PINCODE_LIST:
      const { getPincodeList } = action;
      return {
        ...state,
        getPincodeList,
      };
    case GET_CAMPAIGN_SITE_LIST_ALL:
      const { getCampaignSiteAll } = action;
      return {
        ...state,
        getCampaignSiteAll,
      };
    case GET_CAMPAIGN_PERFORMANCE_REPORT_TREND:
      const { campaignPerformanceReportTrend } = action;
      return {
        ...state,
        campaignPerformanceReportTrend,
      };
    case GET_MEDIA_PLANNING_REPORT_TREND:
      const { mediaPlanningReportTrend } = action;
      return {
        ...state,
        mediaPlanningReportTrend,
      };
    case GET_PINCODE_LIST_ALL:
      const { getPincodeAllList, getPincodeAllFilterList } = action;
      return {
        ...state,
        getPincodeAllList,
        getPincodeAllFilterList
      };
    case GET_CAMPAIGN_PIXEL_LIST:
      const { campaignPixelList } = action;
      return {
        ...state,
        campaignPixelList
      };
    case GET_SAVE_REPORT:
      const { saveReport } = action;
      return {
        ...state,
        saveReport
      };
    case GET_GENERATE_REPORT:
      const { generateReport } = action;
      return {
        ...state,
        generateReport
      };
    case GET_REFRESH_REPORT:
      const { refreshReport } = action;
      return {
        ...state,
        refreshReport
      };
    case GET_MEDIA_SAVE_REPORT:
      const { savedReport } = action;
      return {
        ...state,
        savedReport
      };

    case GET_ACCOUNTS_LIST:
      const { accountsList } = action;
      return {
        ...state,
        accountsList
      }


    case GET_ROADBLOCK_LIST:
      const { roadblockList } = action;
      return {
        ...state,
        roadblockList
      };
    case POST_CAMPAIGN_PERFORMACE_REPORT:
      const { campaignPerformanceList } = action;
      return {
        ...state,
        campaignPerformanceList
      }
    case POST_GEO_REPORT:
      const { geoReportList } = action;
      return {
        ...state,
        geoReportList
      }
    case POST_CREATIVE_PERFORMANCE_REPORT:
      const { creativeAllReportList } = action;
      return {
        ...state,
        creativeAllReportList
      }
    case POST_AGE_GENDER_REPORT:
      const { ageGenderAllReportList } = action;
      return {
        ...state,
        ageGenderAllReportList
      }
      case POST_GENDER_REPORT:
        const { genderAllReportListData } = action;
        return {
          ...state,
          genderAllReportListData
        }
        case POST_AGE_REPORT:
          const { ageAllReportListData } = action;
          return {
            ...state,
            ageAllReportListData
          }


    case RECO_IMPLEMENTATION:
      const { recoImplementation } = action;
      return {
        ...state,
        recoImplementation
      }
    case CREATE_ADVERTISER:
      return {
        ...state,
        advertiserCreateList: action.payload
      }
    case ADVERTISER_LIST:
      // console.log("listAdvertiserAction",action)
      return {
        ...state,
        listAdvertiser: action.listAdvertiser ? action.listAdvertiser : {}
      }

    case GET_MEDIA_PLAN_LIST:
      return {
        ...state,
        mediaPlanList: action.payload
      }
    case GET_OPTIMIZER_LIST:
      return {
        ...state,
        optimizerList: action.payload
      }

    case POST_MEDIA_PLAN_CREATION:
      return {
        ...state,
        mediaplancreationData: action.payload
      }



    case GET_AGE_LIST:
      return {
        ...state,
        ageList: action.payload
      }
    case GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload
      }
    case GET_SUBCATEGORY_LIST:
      return {
        ...state,
        subCategoryList: action.payload
      }




    case GET_PRIMARY_GOAL:
      return {
        ...state,
        primaryGoalList: action.payload
      }
    case GET_SECONDARY_GOAL:
      return {
        ...state,
        secondaryGoalList: action.payload
      }
    case GET_UNMAPPED_CAMPAIGN_LIST:
      return {
        ...state,
        unmappedCampaignList: action.payload
      }
    case POST_CAMPAIGN_PERFORMACE_TOTAL_REPORT:
       return {
        ...state,
        campaignPerformanceTotal: action.campaignPerformanceTotal ? action.campaignPerformanceTotal : {}
      }
    case POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT:
      // console.log("action",action)
       return {
        ...state,
        campaignPerformanceDateWise: action.campaignPerformanceDateWise ? action.campaignPerformanceDateWise : {}
      }
    case POST_CHANNEL_SPEND:
      // console.log("action",action)
        return {
        ...state,
        channelSpend: action.channelSpend ? action.channelSpend : {}
      }
    case POST_WORLD_MAP_DATA:
      // console.log("action",action)
        return {
        ...state,
        worldMapData: action.worldMapData ? action.worldMapData : {}
      }
     case GET_CURRENCY_LIST:
      // console.log("action",action)
        return {
        ...state,
        currencyList: action.currencyList ? action.currencyList : {}
      }

    default:
      return state;
  }
};

export default app;