import * as  config_local from './configuration/configuration_local';
import * as  config_dev from './configuration/configuration_dev';
import * as  config_stage from './configuration/configuration_stage';
import * as  config_prod from './configuration/configuration_prod';
import * as config_stagev3 from './configuration/configuration_stagev3';
import * as config_test from './configuration/configuration_test';

// change here
const environment = 'prod';
// dev, prod, stage, local

let configuration = config_local;
if (environment === 'dev') {
  configuration = config_dev;
} else if (environment === 'stage') {
  configuration = config_stage;
}
else if (environment === 'test') {
  configuration = config_test;
}  
else if (environment === 'stagev3') {
  configuration = config_stagev3;
} else if (environment === 'prod') {
  configuration = config_prod;
}

export const apiBaseUrl = configuration.apiBaseUrl;
export const authenticatio = configuration.apiBaseUrl;
export const apiFileUploadBaseUrl = configuration.apiFileUploadBaseUrl;
export const version = configuration.version;
export const domain = configuration.domain;
export const endPoints = configuration.endPoints;
export const authBaseUrl = configuration.authBaseUrl;
export const constants = configuration.constants;
export const CONTEXT = configuration.CONTEXT;
export const torcaiTracking = configuration.torcaiTracking;
export const tomcatApiUrl = configuration.tomcatApiUrl;
export const iFrameApiUrl = configuration.iFrameApiUrl;
export const imageApiUrl = configuration.imageApiUrl;
export const imageUrl = configuration.imageUrl;
export const demoTenantIds = configuration.tenantIdsToShowDemoData;
export const demoTenantBaseUrl =configuration.demoTenantBaseUrl;

export const serverSidePageSize = 25;
