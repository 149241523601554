export const apiBaseUrl = window.location.protocol === 'https:' ? 'https://integration.torcai.com' : 'http://integration.torcai.com';
export const apiFileUploadBaseUrl = 'https://integration.torcai.com/atd';
export const authBaseUrl = 'https://integration.torcai.com'
export const version = '1.0.0';
export const domain = 'torcai.in';
export const constants = {};
export const endPoints = {

  atd: '/report-atd',
  atdDashboard : '/report-atd/dashboard',
  auth: '/auth',
  geo: '/tai/geo/torcai',
  device:"/auth/v3/config",
  bidder: '/bidder/report',
  io: '/io',
  campaign: '/campaign',
  campaignPmp: '/campaign-pmp',
  creative: '/creative',
  medialist: '/medialist',
  data: '/data',
  ionReport: '/ion/report',
  adUnitBulk: '/adunitbulk',
  adUnitMap: '/adunitmap',
  mediation: '/mediation',
  pmp: '/pmp',
  adgroup: '/adgroup',
  adunit: '/adunit',
  payment: "/payment",
  fb: '/fb'
}
export const CONTEXT = '/atd';
export const torcaiTracking = apiBaseUrl + '/audtrack/postback/track?'
export const tomcatApiUrl = apiBaseUrl.replace(window.location.protocol, "")  + '/content/dmp';
export const iFrameApiUrl = apiBaseUrl + '/dmp/jsv2';
export const imageApiUrl = apiBaseUrl + '/audtrack/param/track?';
export const imageUrl = apiBaseUrl + '/home/centos/source/report-atd/data';
