import routes from '../routes';
export const ROUTE = {

  "ATD_NEW_TEST-DASHBOARD-ALL RIGHTS-ATD::DASHBOARD": `/${routes.mainRoute}/${routes.containers.dashboard}`,

  "ATD_NEW_TEST-MEDIAPLAN-ALL RIGHTS-ATD::MEDIAPLAN": `/${routes.mainRoute}/${routes.containers.mediaPlan}`,

  "ATD_NEW_TEST-CAMPAIGN-ALL RIGHTS-ATD::CAMPAIGN": `/${routes.mainRoute}/${routes.containers.campaignList}`,

  "ATD_NEW_TEST-ADMINMARGIN-ALL RIGHTS-ATD:ADMINMARGIN": `/${routes.mainRoute}/${routes.containers.adminMargin}`,

  "ATD_NEW_TEST-MARGIN-ALL RIGHTS-ATD::MARGIN": `/${routes.mainRoute}/${routes.containers.margin}`,

  "ATD_NEW_TEST-RO-ALL RIGHTS-ATD::RO": `/${routes.mainRoute}/${routes.containers.goals}/${routes.containers.ro}`,

  "ATD_NEW_TEST-UNMAPPEDCAMPAIGN-ALL RIGHTS-ATD::UNMAPPED": `/${routes.mainRoute}/${routes.containers.goals}/${routes.containers.unmappedCampaign}`,

  "ATD_NEW_TEST-RECOMMENDATIONS-ALL RIGHTS-ATD::RECOMMENDATIONS": `/${routes.mainRoute}/${routes.containers.optimizer}`,

  "ATD_NEW_TEST-DATA CONFIG-ALL RIGHTS-ATD::DATACONFIG": `/${routes.mainRoute}/${routes.containers.dataConfig}`,



  'ADMIN-TENANT-VIEW-LOGIN :: ADMIN :: TENANT': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.tenant.name}`,
  'ADMIN-FEATURES-VIEW-LOGIN :: ADMIN :: FEATURES': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.features.name}`,
  'ADMIN-USERS-VIEW-LOGIN :: ADMIN :: USER': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.user.name}`,
  "ADMIN-ACCOUNT-VIEW-LOGIN :: ADMIN :: ACCOUNT": `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.account.name}`,
  'ADMIN-DEMAND PARTNER-VIEW-LOGIN :: ADMIN :: DEMAND PARTNER': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.demandPartner.name}`,
  'ADMIN-SUPPLY PARTNER-VIEW-LOGIN :: ADMIN :: SUPPLY PARTNER': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.supplyPartner.name}`,
  'ADMIN-VARIABLE-VIEW-LOGIN :: ADMIN :: VARIABLE': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.variable.name}`,
  'ADMIN-ROLES-VIEW-LOGIN :: ADMIN :: ROLES': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.roles.name}`,
  'ADMIN-AGREEMENT-VIEW-LOGIN :: ADMIN :: AGREEMENT': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.agreement.name}`,
  'ADMIN-SUPPLY PATH-VIEW-LOGIN :: ADMIN :: SUPPLY PATH': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.supplyPath.name}`,
  "ATD_NEW_TEST-ADVERTISER-ALL RIGHTS-ATD :: ADMIN :: ADVERTISER": `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.advertiser.newAdvertiser}`,




  'ATD_NEW_TEST-AUDIENCE HIERARCHY-ALL RIGHTS-ATD :: AUDIENCE :: AUDIENCE HIERARCHY' : `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.audienceHierarchy.name}`,
  'ATD_NEW_TEST-HIERARCHY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: HIERARCHY MANAGER' : `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.hierarchyManager.name}`,
  'ATD_NEW_TEST-TAXONOMY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: TAXONOMY MANAGER' : `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.taxonomyManager.name}`,
  'ATD_NEW_TEST-DATA CAPTURE-ALL RIGHTS-ATD :: AUDIENCE :: DATA CAPTURE' : `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.dataCapture.name}`






}
