import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import rootStore from './store/store';
import { CONTEXT } from './constants/configuration';
import App from './containers';
import ScrollToTop from './ScrollToTop';
import './assets/iconfonts/mdi/css/materialdesignicons.min.css';

// import './assets/theme/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';

// import './assets/theme/vendor/libs/node-waves/node-waves.css';

// import './assets/theme/vendor/libs/typeahead-js/typeahead.css';

// import './assets/theme/vendor/libs/formvalidation/dist/css/formValidation.min.css';

// import './assets/theme/vendor/css/pages/page-auth.css';

// import './assets/theme/vendor/fonts/fontawesome.css';
// import './assets/theme/vendor/fonts/tabler-icons.css';
// import './assets/theme/vendor/fonts/flag-icons.css';


// import './assets/theme/vendor/css/rtl/core.css';
// import './assets/theme/vendor/css/rtl/theme-default.css';
// import './assets/theme/css/demo.css';

// import './assets/theme/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
// import './assets/theme/vendor/libs/node-waves/node-waves.css';
// import './assets/theme/vendor/libs/typeahead-js/typeahead.css';
// import './assets/theme/vendor/libs/bootstrap-maxlength/bootstrap-maxlength.css';
// import './assets/theme/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
// import './assets/theme/vendor/libs/node-waves/node-waves.css';
// import './assets/theme/vendor/libs/typeahead-js/typeahead.css';
// import './assets/theme/vendor/libs/bootstrap-maxlength/bootstrap-maxlength.css';
// import './assets/theme/vendor/libs/toastr/toastr.css';
// import './assets/theme/vendor/libs/animate-css/animate.css';
// import './assets/theme/vendor/libs/bootstrap-select/bootstrap-select.css';
{/* <link rel="stylesheet" href="../../assets/vendor/libs/bootstrap-select/bootstrap-select.css" /> */}

// import './assets/theme/vendor/css/pages/page-auth.css';

const { store } = rootStore;
Loadable.preloadReady().then(() => {
  ReactDOM.render(<Provider store={store}>
    <Router>
      <ScrollToTop>
        <Route path={CONTEXT} component={App} />
      </ScrollToTop>
    </Router>
  </Provider>, document.getElementById('root'));
});
// ReactDOM.render(
//   <Provider store={stoze}>
//     <Router>
//       <ScrollToTop>
//         <Route path={CONTEXT} component={App} />
//       </ScrollToTop>
//     </Router>
//   </Provider>,
//   document.getElementById('root')
// );
