// Lodash Imports

import { isEqual, get, groupBy, uniq } from 'lodash';

import { MODULE_PERMISSIONS } from '../constants/permission';
import { ROUTE } from '../constants/route';
// Action Type Imports

import {
    BEGIN_AJAX_CALL,
    END_AJAX_CALL,
    PRE_SIGN_IN_SUCCESS,
    SIGN_IN_SUCCESS,
    DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
    GET_ADD_UNIT_LIST,
    GET_ADD_UNIT_GROUP_LIST,
    GET_PMP_LIST,
    GET_TAGLD_MAPPING_LIST,
    GET_TAGLD_MAPPING_ADUNIT_ALL_LIST,
    GET_TAGLD_MAPPING_BULK_LIST,
    GET_CONFIGURATION_DATA,
    GET_FILTER_LIST,
    GET_ION_FILTER_LIST,
    GET_MEDIA_REPORT,
    GET_ION_EXCHANGE_REPORT,
    GET_QPS_REPORT,
    GET_REVENUE_REPORT,
    GET_BIDDER_REPORT,
    GET_MEDIA_PLANNING_REPORT,
    GET_CAMPAIGN_PERFORMANCE_REPORT,
    GET_CAMPAIGN_LIST_ALL_REPORT,
    GET_CAMPAIGN_CATEGORY_TYPE_LIST,
    GET_IO_LIST_ALL_REPORT,
    GET_COUNTRY_LIST,
    AGE_REPORT_DOWNLOAD_REPORT,
    GENDER_REPORT_DOWNLOAD_REPORT,
    GET_STATE_LIST,
    GET_CITY_LIST,
    GET_CAMPAIGN_SUB_CATEGORY,
    GET_ADVERTISER_CREATIVE_GROUP,
    GET_IO_ADVERTISER_LIST_REPORT,
    GET_TENANT_LIST_ALL,
    GET_PLATFORM_LIST_ALL,
    POST_GENDER_CAMPAIGN_ID,
    GET_DEMAND_PARTNER_LIST_ALL,
    GET_DEMAND_PARTNER_SEAT_LIST_ALL,
    GET_UNMAPPED_CAMPAIGN_COUNT,
    GET_TENANT_DEMAND_PARTNER_LIST,
    // GET_SUPPLY_PARTNER_LIST_ALL,
    GET_ADMIN_SUPPLY_PARTNER_LIST_ALL,
    GET_TENANT_SUPPLY_PARTNER_LIST_ALL,
    GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL,
    GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL,
    GET_LIST_FEATURE_ALL,
    GET_FEATURE_LIST_ALL,
    GET_MEDIATION_LIST,
    GET_MEDIATION_AD_GROUP_LIST,
    GET_STATE_LIST_ALL,
    GET_CITY_LIST_ALL,
    GET_PRIVATE_MARKET_PLACE_LIST,
    GET_EXCHANGE_LIST_REPORT,
    GET_AGREEMENT_LIST_ALL,
    GET_AGREEMENT_CONNECTION_LIST,
    GET_ADMIN_USER_LIST_ALL,
    POST_AGE_CAMPAIGN_ID,
    GET_ADMIN_ROLES_LIST_ALL,
    GET_DEMAND_PARTNER_NAME_LIST_ALL,
    GET_USER_PERMISSION,
    GET_APR_LIST_ALL,
    GET_SEGMENT_LIST_ALL,
    GET_HIERARCHY_LIST_ALL,
    GET_DATA_CAPTURE_LIST_ALL,
    GET_AUDIENCE_HIERARCHY_LIST_ALL,
    GET_PINCODE_LIST,
    GET_CAMPAIGN_SITE_LIST_ALL,
    GET_CAMPAIGN_PERFORMANCE_REPORT_TREND,
    GET_MEDIA_PLANNING_REPORT_TREND,
    GET_PINCODE_LIST_ALL,
    GET_CAMPAIGN_PIXEL_LIST,
    GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST,
    GET_SAVE_REPORT,
    GET_MEDIA_SAVE_REPORT,
    GET_GENERATE_REPORT,
    GET_REFRESH_REPORT,
    GET_ACCOUNTS_LIST,
    GET_ROADBLOCK_LIST,
    ADD_CURRENCY_RATE,
    GET_CURRENCY_RATE_LIST,
    GET_USER_REACH_REPORT,
    ADD_TENANT,
    GET_ADVERTISER_LIST,
    POST_CAMPAIGN_PERFORMACE_REPORT,
    POST_GEO_REPORT,
    POST_CREATIVE_PERFORMANCE_REPORT,
    POST_AGE_GENDER_REPORT,
    POST_CREATIVE_DETAILS,
    CREATE_ADVERTISER,
    ADVERTISER_LIST,
    POST_GEO_CAMPAIGN_ID_REPORT,
    UPDATE_ADVERTISER,
    GET_MEDIA_PLAN_LIST,
    GET_OPTIMIZER_LIST,
    GET_AGE_LIST,
    GET_CATEGORY_LIST,
    GET_SUBCATEGORY_LIST,
    POST_MEDIA_CREATE_ACTION,
    GET_PRIMARY_GOAL,
    GET_SECONDARY_GOAL,
    POST_AGE_GENDER_CAMPAIGN_ID,
    CREATE_RO,
    GET_UNMAPPED_CAMPAIGN_LIST,
    FETCH_RO_LIST,
    FETCH_RO_LIST_NAMES,
    GET_RO_LIST_BY_ID,
    UPDATE_RO,
    CREATE_MAP_RO,
    MAP_EXISTING_RO,
    ADVERTISERS_WITHOUT_ACCOUNTS,
    EDIT_ADVERTISER_LIST,
    GOOGLE_AUDIENCE_LIST,
    CAMPAIGN_CREATION,
    GET_ADVERTISER_LIST_ADMINMARGIN,
    GET_CAMPAIGN_LIST_ADMINMARGIN,
    GET_ALL_LIST_ADMIN_MARGIN,
    POST_CREATE_ADMIN_MARGIN,
    GET_ADMIN_MARGIN_LIST_BY_ID,
    POST_UPDATE_ADMIN_MARGIN,
    CAMPAIGN_LIST_BY_ID,
    CAMPAIGN_LIST,
    DV360_AUDIENCE_LIST,
    UPDATE_CAMPAIGN,
    GET_ALL_LIST_MARGIN,
    POST_CREATE_MARGIN,
    GET_MARGIN_LIST_BY_ID,
    POST_UPDATE_MARGIN,
    GET_OPTIMIZER_RECOMMENDATION_LIST,
    GET_RECOMMENDATION_LIST,
    POST_UPDATE_RECOMMENDATION,
    DOMAIN_REPORT,
    FACEBOOK_AUDIENCE_LIST,
    POST_CSV_DOWNLOAD,
    GET_MEDIA_PLAN_DOWNLOAD,
    POST_CREATIVE_REPORT_TABLE,
    POST_CSV_DOWNLOAD_PERFORMANCE_REPORTS,
    POST_CSV_DOWNLOAD_CREATIVE_REPORTS,
    POST_CSV_DOWNLOAD_GEO_REPORTS,
    POST_CSV_DOWNLOAD_DOMAIN_REPORTS,
    POST_PERFORMANCE_REPORT,
    UPDATE_CAMPAIGN_STATUS,
    GET_PLATFORM_WISE_DATA,
    RECO_IMPLEMENTATION,
    POST_SIGN_UP,
    DEMO_GRAPHIC_DOWNLOAD_REPORT,
    DEVICE_REPORT,
    DEVICE_REPORT_DOWNLOAD,
    TENTITIVE_INVOICE_LIST,
    INVOICE_DETAILS_LIST,
    EDIT_INVOICE_TENANT_LIST,
    INVOICE_TENANT_LIST,
    HISTORY_LIST,
    GET_STATUS_PDF,
    GET_DSP_LIST_ALL_DATA,
    POST_CREATE_DSP_PLATFORM,

    GET_NEW_SEGMENT_LIST_ALL,
    POST_GOOGLE_VERIFICATION_URL,
    POST_GOOGLE_ACCOUNTS,
    POST_CAMPAIGN,
    POST_SYNC_CAMPAIGN,
    GET_USER_DETAILS,
    GENRATE_GOOGLE_URL,

    // facebook login
    GET_FACEBOOK_USER_ACCOUNTS,
    FACEBOOK_SYNC_CAMPAIGN,

    // dv360 login
    GET_DV360_URL,
    POST_DV360_VERIFICATION_URL,
    GET_CAMPAIGN,
    GET_LIST_BY_ID_DSP_PLATFORM,
    POST_UPDATE_DSP_PLATFORM,
    GET_VARIABLE_LIST_ALL_DATA,
    POST_CREATE_VARIABLE,
    GET_LIST_BY_ID_VARIABLE,
    POST_UPDATE_VARIABLE,
    


    // GAM LOGIN
    GET_GAM_URL,
    POST_GAM_VERIFICATION_URL,
    POST_GAM_CAMPAIGN_LIST,
    POST_GAM_SYNC_CAMPAIGN,
    POST_TIKTOK_LOCATION,
    GET_GEO_LOCATION,
    GET_FACEBOOK_REFETCH_CAMPAIGN,
    POST_DV360_ACCOUNTS,
    POST_GENDER_REPORT,
    POST_AGE_REPORT,
    POST_DV360_CAMPAIGN,
    GET_FACEBOOK_ACCOUNTS,
    GET_LIST_DV_360,
    GET_ACCOUNT_ID_DV,
    POST_SYNC_CAMPAIGN_DV_360,
    GET_CAMPAIGN_CUSTOM,
    POST_WORLD_MAP_DATA,
    PERTICULAR_WORLD_MAP_DATA,
    POST_DELETE_RECOMMENDATION,
    POST_CAMPAIGN_PERFORMACE_TOTAL_REPORT,
    POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT,
    POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT_DOWNLOAD,
    POST_CHANNEL_SPEND,
    GET_CURRENCY_LIST
} from '../constants/actionType';

// Api Import
import {
    getPreSignInData,
    getSignInData,
    getReSignInData,
    logoutApi,
    getConfigurationData,
    getFilterListApi,
    getIonFilterListApi,
    getAdUnitListApi,
    editAdUnitApi,
    addAdUnitApi,
    deleteAdUnitApi,
    getAdUnitGroupListApi,
    getAdminSupplypartnerListApi,
    getAdminTenantSupplypartnerListApi,
    deleteAdUnitGroupApi,
    addAdUnitGroupApi,
    getPMPListApi,
    addPMPApi,
    deletePMPApi,
    getMediaReportApi,
    getIonExchangeApi,
    getQpsReportApi,
    getRevenueReportApi,
    getBidderReportApi,
    getMediaPlanningReportApi,
    getCampaignPerformanceReportApi,
    getCampaignPerformanceReportDownloadApi,
    getIonExchangeDownloadApi,
    getCampaignListAllReportApi,
    getCampaignEditIdReportApi,
    getCampaignCategoryTypeApi,
    getIoListAllReportApi,
    addIoReportApi,
    deleteIoApi,
    getCountryListApi,
    getStateListApi,
    getCityListApi,
    getCampaignSubCategoryReportApi,
    facebookCampaignPagesApi,
    facebookCampaignNamesApi,
    getAdvertiserCreativeGroupApi,
    getIoAdvertisersReportApi,
    addCampaignApi,
    addCreativeApi,
    deleteCampaignApi,
    getIoEditIdReportApi,
    deleteCreativeApi,
    editCreativeApi,
    uploadFileActionApi,
    getTenantApi,
    addTenantApi,
    getSupplyPartnerApi,
    addSupplyPartnerApi,
    deleteSupplyPartnerApi,
    editSupplyPartnerApi,
    getDemandpartnerListApi,
    getDemandpartnerSeatListApi,
    getTenantDemandpartnerListApi,
    addAdminDemandPartnerApi,
    editTenantApi,
    deleteTenantApi,
    getListFeatureApi,
    getFeatureListApi,
    postGenderGraphCampaignIdApi,
    addFeatureApi,
    addFeatureAttributeApi,
    getMediationApi,
    addMediationApi,
    deleteMediationApi,
    getTagldMappingListApi,
    getTagldMappingDownloadCSVApi,
    getTagldMappingListadunitAllApi,
    tagldMappingAddAdunitmapApi,
    uploadSiteFileBulkCSVApi,
    tagldMappingBulkDownloadApi,
    gettagldMappingBulkListApi,
    getMediationAdGroupListApi,
    addMediationAdGroupListApi,
    deleteMediationAdGroupApi,
    getPrivateMarketPlaceApi,
    addPrivateMarketPlaceApi,
    deletePrivateMarketPlaceApi,
    getAdminVariableListApi,
    addAdminVariableApi,
    getAdminUserListApi,
    getAdminUserSingleListApi,
    addAdminUserApi,
    getRolesListApi,
    getAdminRoleSingleListApi,
    addAdminRolesApi,
    getAgreementListApi,
    ageDataReportDownloadApi,
    genderDataReportDownloadApi,
    getAgreementConnectionListApi,
    addAdminAgreementApi,
    getIONSupplypathListApi,
    updateIONSupplypathApi,
    getPVTSupplypathListApi,
    getDemandPartnerNamesFilterApi,
    roleAccessPermissions,
    getAprApi,
    getSegmentListApi,
    getHierarchyListApi,
    addAprApi,
    getAprEditApi,
    aprDownloadApi,
    addHierarchyApi,
    deleteHierarchyApi,
    addSegmentApi,
    getDataPixelEditApi,
    addDataCaptureApi,
    getDataCaptureApi,
    getDataCaptureListApi,
    deleteDataCaptureApi,
    getsegmentEditApi,
    getAudhierarchyListApi,
    downloadingConversionTagApi,
    getAudiancePixelMicrosApi,
    checkTagAndPostbackuniqueApi,
    uploadScriptApi,
    getPinCodeListApi,
    forgotPasswordApi,
    validateResetPasswordApi,
    changePasswordApi,
    getCampaignSiteApi,
    uploadSiteFileApi,
    addCampaignSiteApi,
    deleteCampaignSiteApi,
    getBidderReportDownloadApi,
    getMediaPlanningDownloadReportApi,
    getMediaReportDownloadApi,
    getRevenueReportDownloadApi,
    getQpsReportDownloadApi,
    getCampaignPixelListApi,
    deleteSegmentApi,
    deleteTagMapApi,
    addRoadblock,
    getRoadblockListApi,
    getCampaignPerformanceReportFilterListApi,
    getSaveReportApi,
    getMediaSaveReportApi,
    getGenerateReportApi,
    addSaveReportApi,
    refreshSaveReportApi,
    getRefreshReportApi,
    getAccountsListApi,
    addAccountApi,
    editAccountApi,
    addPlatformApi,
    editPlatformApi,
    getPlatformListApi,
    getCurrencyRateListApi,
    addCurrencyRateApi,
    getPlatformSingleListApi,
    getUserReachReportApi,
    getUserReachReportDownloadApi,
    editUpTenantApi,
    addUpTenantApi,
    uploadPlatformFileActionApi,
    getListAdvertiserApi,
    campaignPerformanceReportApi,
    postGeoReportApi,
    postCreativeReportApi,
    postAgeGenderReportApi,
    creativeDetailsApi,
    postGeoCampaignIdApi,
    createAdvertiserApi,
    listAdvertiserApi,
    updateAdvertiserApi,
    getMediaPlanListApi,
    getOptimizerListApi,
    getOptimizerStatusApi,
    getAgeListApi,
    getCategoryApi,
    getSUbCategoryApi,
    postMediaPlanCreationApi,
    postMediaPlanApi,
    getPrimaryGoalApi,
    getSecondaryGoalApi,
    postAgeGenderCampaignIdApi,
    createRoApi,
    getUnmappedCampaignListApi,
    postUnmappedCampaignListApi,
    getRoListApi,
    getRoMppedCampaignList,
    postUpdateStatusRo,
    downloadRoList,
    deleteMappedCampaignWithRo,
    postCampaignListNamesWithRo,
    getCountUnmappedCampaign,
    getRoListNamesApi,
    getRoListByIdApi,
    updateRoApi,
    createMapRoApi,
    mapExistingRo,
    getAdvertiserWithoutAccApi,
    editAdvertiserListApi,
    getGoogleAudienceList,
    createCampaignApi,
    getAdvertiserListApi,
    getAllCampaignListApi,
    getAllListAdminMarginApi,
    postCreationAdminMarginApi,
    getListByIdAdminMarginApi,
    postUpdatetionAdminMarginApi,
    campaignListById,
    postCampaignList,
    getDv360AudienceList,
    updateCampaignApi,
    getAllListMarginApi,
    postCreationMarginApi,
    getListByIdMarginApi,
    postUpdatetionMarginApi,
    getOptimizerListRecommendationApi,
    getOptimizerListRecommendationApiForSelectedTenant,
    getRecommendationApi,
    postUpdateRecommendationApi,
    getFacebookAudienceList,
    domainReportApi,
    postDownloadCsvApi,
    getMediaplanPdfApi,
    postCreativeReportTableApi,
    postPerformanceReportDownloadCsvApi,
    postCreativeReportDownloadCsvApi,
    postGeoReportDownloadCsvApi,
    postDomainReportDownloadCsvApi,
    postPerformanceReportApi,
    updateCampaignStatusApi,
    getPlaformWiseApi,
    postRecoImplementationApi,
    postSignupApi,
    demoGraphicReportDownloadApi,
    deviceReportApi,
    deviceReportDownloadApi,

    getTentitiveInvoiceApi,
    getInvoiceDetailsApi,
    editTenantDetailsApi,
    getTenantDetailsApi,
    getBillingHistoryData,
    getAddPaymentRedirectUrl,
    getStatusPdfCheckApi,
    getListDspPlatformApi,
    postDspPlatfornCreationApi,
    audienceHierarchyDownloadCSVApi,

    getGoogleUrlApi,

    // ion
    getNewSegmentListApi,
    postGoogleVerificationApi,
    postGoogleAccountApi,
    postCampaignApi,
    postSyncCampaignApi,
    getInfomoSeatGoogleApi,
    getInfomoSeatFacebookApi,
    getGoogleUserInformationApi,

    // facebook signin
    getFbUserInfoApi,
    facebookSyncCampaignApi,
    getFbUserAccountsApi,
    getFbUserRedirectUrlApi,

    // dv360 login
    getDv360UrlApi,
    postDV360VerificationApi,
    campaignDetailsApi,
    getListByIdDspPlatformApi,
    postDspPlatfornUpdateApi,
    getVariableListApi,
    postVariableCreationApi,
    getListByIdVariableApi,
    postVariableUpdateApi,


    // GAM LOGIN
    getGamUrlApi,
    postGamVerificationApi,
    postGamCampaignApi,
    postGamSyncCampaignApi,

    postTiktokLocationApi,
    getGeoLocationApi,
    getGeoLocationApiFacebook,
    getFbResyncApi,
    postDv360AccountApi,
    postDv360CampaignApi,
    postGenderGraphReportApi,
    postAgeGraphReportApi,
    checkDuplicateNameApi,
    checkDuplicateGoogleCampaignApi,
    getFacebookAccountApi,
    deleteAccountsApi,
    DV360CampaignNamesApi,
     getGenrateAccountIdDV360Api,
    postSyncCampaignDV360Api,
    getCampaignCustomApi,
    postWorldMapApi,
    postWorldGeoCampaignApi,
    postAgeGraphCampaignIdApi,
    postDeleteRecommendationApi,
    campaignPerformanceTotalReportApi,
    campaignPerformanceReportDatewiseApi,
    campaignPerformanceReportDatewiseDownloadApi,
    postChannelSpendApi,
    getCurrencyListApi
} from '../api';

// ion actions start
export const getNewSegmentListAction = ({ tenantId, authToken, userId, hierarchyVar, parentVar, grandVar, type }) => async dispatch => {
    let getNewSegmentList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getNewSegmentList = await getNewSegmentListApi({ tenantId, authToken, userId, hierarchyVar, parentVar, grandVar, type });
        dispatch({
            type: GET_NEW_SEGMENT_LIST_ALL,
            getNewSegmentList,
        });
        return getNewSegmentList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ion actions end

export const beginAjaxCallAction = () => async dispatch => {
    dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
    });
};

export const endAjaxCallAction = () => async dispatch => {
    dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
    });
};

// export const getConfigurationDataAction = (attributesObj) => async dispatch => {
//     let configurationData = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         // configurationData = await getConfigurationData(attributesObj);
//         configurationData = await getAdminVariableListApi(attributesObj);
//         dispatch({
//             type: GET_CONFIGURATION_DATA,
//             configurationData,
//         });
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const getFilterListAction = (tenantId, authToken, userId) => async dispatch => {
    let filterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        filterList = await getFilterListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_FILTER_LIST,
            filterList,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getIonFilterListAction = (props) => async dispatch => {
    let ionFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ionFilterList = await getIonFilterListApi(props);
        dispatch({
            type: GET_ION_FILTER_LIST,
            ionFilterList,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const dispatchAuthenticationDataAction = ({ preSignInState, signInData, preSignInData }) => async dispatch => {
    dispatch({
        type: DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
        preSignInState,
    });
    dispatch({
        type: PRE_SIGN_IN_SUCCESS,
        preSignInData,
    });
    dispatch({
        type: SIGN_IN_SUCCESS,
        signInData,
    });
};

export const dispatchPreSignInData = preSignInState => async dispatch => {
    dispatch({
        type: DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
        preSignInState,
    });
    localStorage.setItem('PreSignInState', JSON.stringify(preSignInState));
};

export const clearPreSignInData = () => async dispatch => {
    dispatch({
        type: DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
        preSignInState: {},
    });
    dispatch({
        type: PRE_SIGN_IN_SUCCESS,
        preSignInData: {},
    });
    localStorage.setItem('PreSignInData', JSON.stringify({}));
};

export const getPreSignInDataAction = preSignInReqBody => async dispatch => {
    let preSignInData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        preSignInData = await getPreSignInData(preSignInReqBody);
        dispatch({
            type: PRE_SIGN_IN_SUCCESS,
            preSignInData,
        });
        localStorage.setItem('PreSignInData', JSON.stringify(preSignInData));
        return preSignInData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const postSignupAction = payload => async dispatch => {
    let signUpRegistration = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        signUpRegistration = await postSignupApi(payload);
        dispatch({
            type: POST_SIGN_UP,
            signUpRegistration,
        });
        return signUpRegistration;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};






export const getSignInDataAction = signInReqBody => async dispatch => {
    let signInData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        signInData = await getSignInData(signInReqBody);
        if (isEqual(get(signInData, 'responseCode.status'), 'Success')) {
            dispatch({
                type: SIGN_IN_SUCCESS,
                signInData,
            });
            localStorage.setItem('AuthData', JSON.stringify(signInData));

            let preSignInData = JSON.parse(localStorage.getItem('PreSignInData'));
            let preSignInState = JSON.parse(localStorage.getItem('PreSignInState'));
            let getUserPermissionList = {};
            let data = await roleAccessPermissions({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            let permissionsList = [];
            data.responseList.forEach((a) => {
                if (a.roleDetails && a.roleDetails.length) {
                    a.roleDetails.forEach((b) => {
                        permissionsList.push((`${b.moduleName}-${b.featureName}-${b.useCaseName}-${b.bcrumName}`.toUpperCase()).trim());
                    });
                }
            });
            getUserPermissionList = uniq(permissionsList);
            let defaultMainRoute = '';
            // default route dashboard
            let defaultRoute = {};
            let dashboard = MODULE_PERMISSIONS.DASHBOARD.find(a => getUserPermissionList.indexOf(a) > -1);
            if (dashboard && ROUTE[dashboard]) {
                defaultRoute.dashboard = ROUTE[dashboard];
                defaultMainRoute = ROUTE[dashboard];
            }

            // default route mediaplan
            let mediaPlan = MODULE_PERMISSIONS.MEDIAPLAN.find(a => getUserPermissionList.indexOf(a) > -1);
            if (mediaPlan && ROUTE[mediaPlan]) {
                defaultRoute.mediaPlan = ROUTE[mediaPlan];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[mediaPlan];
                }
            }

            // default route Admin Margin
            let adminMargin = MODULE_PERMISSIONS.ADMINMARGIN.find(a => getUserPermissionList.indexOf(a) > -1);
            if (adminMargin && ROUTE[adminMargin]) {
                defaultRoute.adminMargin = ROUTE[adminMargin];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[adminMargin];
                }
            }

            // default route  Margin
            let margin = MODULE_PERMISSIONS.MARGIN.find(a => getUserPermissionList.indexOf(a) > -1);
            if (margin && ROUTE[margin]) {
                defaultRoute.margin = ROUTE[margin];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[margin];
                }
            }

            // default route  RO
            let ro = MODULE_PERMISSIONS.RO.find(a => getUserPermissionList.indexOf(a) > -1);
            if (ro && ROUTE[ro]) {
                defaultRoute.ro = ROUTE[ro];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[ro];
                }
            }

            // default route  UNMAPPEDCAMPAIGN
            let unmappedCampaign = MODULE_PERMISSIONS.UNMAPPEDCAMPAIGN.find(a => getUserPermissionList.indexOf(a) > -1);
            if (unmappedCampaign && ROUTE[unmappedCampaign]) {
                defaultRoute.unmappedCampaign = ROUTE[unmappedCampaign];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[unmappedCampaign];
                }
            }

            // default route  OPTIZER
            let optimizer = MODULE_PERMISSIONS.OPTIMIZER.find(a => getUserPermissionList.indexOf(a) > -1);
            if (optimizer && ROUTE[optimizer]) {
                defaultRoute.optimizer = ROUTE[optimizer];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[optimizer];
                }
            }




            // default route campaign
            let campaign = MODULE_PERMISSIONS.CAMPAIGN.find(a => getUserPermissionList.indexOf(a) > -1);
            if (campaign && ROUTE[campaign]) {
                defaultRoute.campaign = ROUTE[campaign];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[campaign];
                }
            }



            // default route admin
            let admin = MODULE_PERMISSIONS.ADMIN.find(a => getUserPermissionList.indexOf(a) > -1);
            if (admin && ROUTE[admin]) {
                defaultRoute.admin = ROUTE[admin];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[admin];
                }
            }


            localStorage.setItem('getUserPermissionList', JSON.stringify(getUserPermissionList));
            localStorage.setItem('defaultRoute', JSON.stringify(defaultRoute));
            localStorage.setItem('defaultMainRoute', JSON.stringify(defaultMainRoute));

            dispatch({
                type: GET_USER_PERMISSION,
                getUserPermissionList,
                defaultRoute,
                defaultMainRoute
            });
            // dispatch(await getRoleAccessPermissions({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            dispatch(getConfigurationDataAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            dispatch(getCountryAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            // dispatch(getStateAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            // dispatch(getCityAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            // dispatch(getPincodeAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            return signInData;
        }
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getReSignInDataAction = (reSignInReqBody, authToken) => async dispatch => {
    let reSignInData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        reSignInData = await getReSignInData({ reSignInReqBody, authToken });
        if (isEqual(get(reSignInData, 'responseCode.status'), 'Success')) {
            dispatch({
                type: SIGN_IN_SUCCESS,
                signInData: reSignInData,
            });
            localStorage.setItem('AuthData', JSON.stringify(reSignInData));
            return reSignInData;
        }
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const logoutAction = (logoutReqBody, authToken) => async dispatch => {
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        await logoutApi({ logoutReqBody, authToken });
        dispatch({
            type: SIGN_IN_SUCCESS,
            signInData: {},
        });
        dispatch({
            type: PRE_SIGN_IN_SUCCESS,
            preSignInData: {},
        });
        dispatch({
            type: DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
            preSignInState: {},
        });
        dispatch({
            type: GET_CONFIGURATION_DATA,
            configurationData: {},
        });
        localStorage.setItem('AuthData', JSON.stringify({}));
        localStorage.setItem('PreSignInData', JSON.stringify({}));
        localStorage.setItem('PreSignInState', JSON.stringify({}));
        localStorage.setItem('defaultRoute', JSON.stringify([]));
        localStorage.setItem('defaultMainRoute', JSON.stringify([]));
        localStorage.setItem('getUserPermissionList', JSON.stringify([]));
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ---* ION * ---

// * addUnit *
export const getAddUnitListAction = (tenantId, authToken, userId) => async dispatch => {
    let adUnitList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adUnitList = await getAdUnitListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ADD_UNIT_LIST,
            adUnitList,
        });
        return adUnitList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editAdUnitAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editAdUnit = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editAdUnit = await editAdUnitApi({ tenantId, authToken, userId, editId });
        return editAdUnit;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const addAdUnitAction = (tenantId, authToken, userId, adUnitReqBody, addParams) => async dispatch => {
    let addAdUnitData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addAdUnitData = await addAdUnitApi({ tenantId, authToken, userId, adUnitReqBody, addParams });
        return addAdUnitData && addAdUnitData.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteAdUnitAction = (AdUnitObj) => async dispatch => {
    let deleteAdUnitData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteAdUnitData = await deleteAdUnitApi(AdUnitObj);
        return deleteAdUnitData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// * AdUnit Group *
export const getAddUnitGroupListAction = (tenantId, authToken, userId) => async dispatch => {
    let adUnitGroupList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adUnitGroupList = await getAdUnitGroupListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ADD_UNIT_GROUP_LIST,
            adUnitGroupList,
        });
        return adUnitGroupList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteAdUnitGroupAction = (AdUnitGroupObj) => async dispatch => {
    let deleteAdUnitGroup = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteAdUnitGroup = await deleteAdUnitGroupApi(AdUnitGroupObj);
        return deleteAdUnitGroup;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const addAdUnitGroupAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addAdUnitGruopData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addAdUnitGruopData = await addAdUnitGroupApi({ tenantId, authToken, userId, payload, addParams });
        return addAdUnitGruopData && addAdUnitGruopData.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// * Private Market Place *
export const getPMPListAction = (tenantId, authToken, userId) => async dispatch => {
    let pmpList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pmpList = await getPMPListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_PMP_LIST,
            pmpList,
        });
        return pmpList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRoadblockList = (tenantId, authToken, userId) => async dispatch => {
    let roadblockList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        roadblockList = await getRoadblockListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ROADBLOCK_LIST,
            roadblockList,
        });
        return roadblockList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addPMPAction = (addPMPObj) => async dispatch => {
    let addPMP = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addPMP = await addPMPApi(addPMPObj);
        return addPMP && addPMP.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addRoadblockAction = (addRoadblockObj) => async dispatch => {
    let addRoadblockRes = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addRoadblockRes = await addRoadblock(addRoadblockObj);
        return addRoadblockRes && addRoadblockRes.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deletePMPAction = (PMPObj) => async dispatch => {
    let deletePMP = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deletePMP = await deletePMPApi(PMPObj);
        return deletePMP;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// Add Mediation AdGroup

export const getMediationAdGroupListAction = (mediationAdGroupObj) => async dispatch => {
    let mediationAdGroupList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediationAdGroupList = await getMediationAdGroupListApi(mediationAdGroupObj);
        dispatch({
            type: GET_MEDIATION_AD_GROUP_LIST,
            mediationAdGroupList,
        });
        return mediationAdGroupList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addMediationAdGroupAction = (mediationAdGroupObj) => async dispatch => {
    let addMediationAdGroup = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addMediationAdGroup = await addMediationAdGroupListApi(mediationAdGroupObj);
        return addMediationAdGroup;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteMediationAdGroupAction = (mediationAdGroupObj) => async dispatch => {
    let deleteMediationAdGroup = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteMediationAdGroup = await deleteMediationAdGroupApi(mediationAdGroupObj);
        return deleteMediationAdGroup;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// * mediation api *

export const getMediationListAction = (mediationObj) => async dispatch => {
    let mediationList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediationList = await getMediationApi(mediationObj);
        dispatch({
            type: GET_MEDIATION_LIST,
            mediationList,
        });
        return mediationList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addMediationAction = (mediationObj) => async dispatch => {
    let addMediation = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addMediation = await addMediationApi(mediationObj);
        return addMediation;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteMediationAction = (mediationObj) => async dispatch => {
    let deleteMediation = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteMediation = await deleteMediationApi(mediationObj);
        return deleteMediation;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// * TagldMapping *
export const getTagldMappingListAction = (tagldMappingObj) => async dispatch => {
    let tagldMappingList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingList = await getTagldMappingListApi(tagldMappingObj);
        dispatch({
            type: GET_TAGLD_MAPPING_LIST,
            tagldMappingList,
        });
        return tagldMappingList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteTagIdMappingAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteTagMap = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteTagMap = await deleteTagMapApi({ tenantId, authToken, userId, deleteId });
        return deleteTagMap && deleteTagMap.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getTagldMappingDownloadCSVAction = (tagldMappingDownloadCSVObj) => async dispatch => {
    let tagldMappingDownloadCSV = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingDownloadCSV = await getTagldMappingDownloadCSVApi(tagldMappingDownloadCSVObj);
        return tagldMappingDownloadCSV;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getTagldMappingListadunitAllAction = (tagldMappingListadunitAllObj) => async dispatch => {
    let tagldMappingListadunitAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingListadunitAll = await getTagldMappingListadunitAllApi(tagldMappingListadunitAllObj);
        dispatch({
            type: GET_TAGLD_MAPPING_ADUNIT_ALL_LIST,
            tagldMappingListadunitAll,
        });
        return tagldMappingListadunitAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const tagldMappingtagldMappingAddAdunitmapAction = (tagldMappingAddAdunitmapObj) => async dispatch => {
    let tagldMappingAddAdunitmap = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingAddAdunitmap = await tagldMappingAddAdunitmapApi(tagldMappingAddAdunitmapObj);
        return tagldMappingAddAdunitmap;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// bulk upload
export const uploadSiteFileBulkCSVAction = (payload) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadSiteFileBulkCSVApi(payload);
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const gettagldMappingBulkListAction = (tagldMappingBulkListObj) => async dispatch => {
    let tagldMappingBulkList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingBulkList = await gettagldMappingBulkListApi(tagldMappingBulkListObj);
        dispatch({
            type: GET_TAGLD_MAPPING_BULK_LIST,
            tagldMappingBulkList,
        });
        return tagldMappingBulkList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const tagldMappingBulkDownloadAction = (tagldMappingBulkDownloadObj) => async dispatch => {
    let tagldMappingBulkDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingBulkDownload = await tagldMappingBulkDownloadApi(tagldMappingBulkDownloadObj);
        return tagldMappingBulkDownload;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getMediaReportAction = (tenantId, authToken, userId, mediaReportReqBody) => async dispatch => {
    let mediaReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaReport = await getMediaReportApi({ tenantId, authToken, userId, mediaReportReqBody });
        dispatch({
            type: GET_MEDIA_REPORT,
            mediaReport,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getQPSReportAction = (tenantId, authToken, userId, qpsReportReqBody) => async dispatch => {
    let qpsReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        qpsReport = await getQpsReportApi({ tenantId, authToken, userId, qpsReportReqBody });
        dispatch({
            type: GET_QPS_REPORT,
            qpsReport,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRevenueReportAction = (tenantId, authToken, userId, revenueReportReqBody) => async dispatch => {
    let revenueReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        revenueReport = await getRevenueReportApi({ tenantId, authToken, userId, revenueReportReqBody });
        dispatch({
            type: GET_REVENUE_REPORT,
            revenueReport,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getIonExchangeAction = (props) => async dispatch => {
    let ionExchange = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ionExchange = await getIonExchangeApi(props);
        dispatch({
            type: GET_ION_EXCHANGE_REPORT,
            ionExchange,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getIonExchangeDownloadReportAction = (props) => async dispatch => {
    let ionExchangeDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ionExchangeDownloadReport = await getIonExchangeDownloadApi(props);
        return (ionExchangeDownloadReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin api

export const getAdminSupplypartnerListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let adminSupplypartnerData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminSupplypartnerData = await getAdminSupplypartnerListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ADMIN_SUPPLY_PARTNER_LIST_ALL,
            adminSupplypartnerData,
        });
        return adminSupplypartnerData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAdminTenantSupplypartnerListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let adminTenantSupplypartnerData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminTenantSupplypartnerData = await getAdminTenantSupplypartnerListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_TENANT_SUPPLY_PARTNER_LIST_ALL,
            adminTenantSupplypartnerData,
        });
        return adminTenantSupplypartnerData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin Variable Api
export const getConfigurationDataAction = (objVariable) => async dispatch => {
    let configurationData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        // configurationData = await getConfigurationData(attributesObj);
        configurationData = await getAdminVariableListApi(objVariable);
        dispatch({
            type: GET_CONFIGURATION_DATA,
            configurationData,
        });
        return configurationData
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// export const addAdminAttrNameVariableAction = (objVariable) => async dispatch => {
//     let adminAttrNameVariableData = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         adminAttrNameVariableData = await addAdminVariableApi(objVariable);
//         // dispatch({
//         //     type: GET_ADMIN_VARIABLE_LIST_ALL,
//         //     adminAttrNameVariableData,
//         // });
//         return (adminAttrNameVariableData || {});
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

// export const addAdminAttributeValuesVariableAction = (objVariable) => async dispatch => {
//     let adminAttributeValuesVariableData = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         adminAttributeValuesVariableData = await addAdminVariableApi(objVariable);
//         // dispatch({
//         //     type: GET_ADMIN_VARIABLE_LIST_ALL,
//         //     adminAttributeValuesVariableData,
//         // });
//         return adminAttributeValuesVariableData;
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const addAdminVariableAction = (objVariable) => async dispatch => {
    let adminAttrNameVariableData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminAttrNameVariableData = await addAdminVariableApi(objVariable);
        // dispatch({
        //     type: GET_ADMIN_VARIABLE_LIST_ALL,
        //     adminAttrNameVariableData,
        // });
        return (adminAttrNameVariableData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// admin User Api
export const getAdminUserListAction = (objVariable) => async dispatch => {
    let adminUserData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminUserData = await getAdminUserListApi(objVariable);
        dispatch({
            type: GET_ADMIN_USER_LIST_ALL,
            adminUserData,
        });
        return adminUserData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAdminUserSingleListAction = (objVariable) => async dispatch => {
    let singleUserData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        singleUserData = await getAdminUserSingleListApi(objVariable);
        return singleUserData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addAdminUserAction = (objVariable) => async dispatch => {
    let adminUserData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminUserData = await addAdminUserApi(objVariable);
        return (adminUserData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin Roles Api
export const getAdminRolesListAction = (objVariable) => async dispatch => {
    let adminRolesData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminRolesData = await getRolesListApi(objVariable);
        dispatch({
            type: GET_ADMIN_ROLES_LIST_ALL,
            adminRolesData,
        });
        return adminRolesData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAdminRoleSingleListAction = (objVariable) => async dispatch => {
    let singleRoleData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        singleRoleData = await getAdminRoleSingleListApi(objVariable);
        return singleRoleData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const addAdminRolesAction = (objVariable) => async dispatch => {
    let adminRolesrData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminRolesrData = await addAdminRolesApi(objVariable);
        return (adminRolesrData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin Agreement Api
export const getAgreementListAction = (objVariable) => async dispatch => {
    let adminAgreementData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminAgreementData = await getAgreementListApi(objVariable);
        dispatch({
            type: GET_AGREEMENT_LIST_ALL,
            adminAgreementData,
        });
        return adminAgreementData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addAdminAgreementAction = (objVariable) => async dispatch => {
    let adminAgreementData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminAgreementData = await addAdminAgreementApi(objVariable);
        return (adminAgreementData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin Agreement Api
export const getAgreementConnectionListAction = (objVariable) => async dispatch => {
    let connectionAgreementData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        connectionAgreementData = await getAgreementConnectionListApi(objVariable);
        dispatch({
            type: GET_AGREEMENT_CONNECTION_LIST,
            connectionAgreementData,
        });
        return connectionAgreementData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ---- * supplay path * ----
export const getIONSupplypathAction = (objVariable) => async dispatch => {
    let ionSupplypathData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ionSupplypathData = await getIONSupplypathListApi(objVariable);
        dispatch({
            type: GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL,
            ionSupplypathData,
        });
        return (ionSupplypathData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getPVTSupplypathAction = (objVariable) => async dispatch => {
    let pvtSupplypathData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pvtSupplypathData = await getPVTSupplypathListApi(objVariable);
        dispatch({
            type: GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL,
            pvtSupplypathData,
        });
        return (pvtSupplypathData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const updateIONSupplypathAction = (objVariable) => async dispatch => {
    let updateIONSupplypathData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateIONSupplypathData = await updateIONSupplypathApi(objVariable);
        return (updateIONSupplypathData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
// ---- * Bidder Action * ----

export const getBidderReportAction = (tenantId, authToken, userId, bidderReportReqBody) => async dispatch => {
    let bidderReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        bidderReport = await getBidderReportApi({ tenantId, authToken, userId, bidderReportReqBody });
        dispatch({
            type: GET_BIDDER_REPORT,
            bidderReport,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getMediaPlanningReportAction = (tenantId, authToken, userId, mediaPlanningReportReqBody) => async dispatch => {
    let mediaPlanningReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaPlanningReport = await getMediaPlanningReportApi({ tenantId, authToken, userId, mediaPlanningReportReqBody });
        dispatch({
            type: GET_MEDIA_PLANNING_REPORT,
            mediaPlanningReport,
        });
        return (mediaPlanningReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getMediaSaveReportAction = (tenantId, authToken, userId) => async dispatch => {
    let savedReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        savedReport = await getMediaSaveReportApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_MEDIA_SAVE_REPORT,
            savedReport,
        });
        return savedReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getGenerateReportAction = (tenantId, authToken, userId, mediaPlanningReportReqBody) => async dispatch => {
    let generateReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        generateReport = await getGenerateReportApi({ tenantId, authToken, userId, mediaPlanningReportReqBody });;
        dispatch({
            type: GET_GENERATE_REPORT,
            generateReport,
        });
        return generateReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRefreshAction = (tenantId, authToken, userId, rowId, payload) => async dispatch => {
    let refreshReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        refreshReport = await getRefreshReportApi({ tenantId, authToken, userId, rowId, payload });;
        dispatch({
            type: GET_REFRESH_REPORT,
            refreshReport,
        });
        return refreshReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getMPRTrendAction = (tenantId, authToken, userId, mediaPlanningReportReqBody) => async dispatch => {
    let mediaPlanningReportTrend = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaPlanningReportTrend = await getMediaPlanningReportApi({ tenantId, authToken, userId, mediaPlanningReportReqBody });
        dispatch({
            type: GET_MEDIA_PLANNING_REPORT_TREND,
            mediaPlanningReportTrend,
        });
        return (mediaPlanningReportTrend || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};






// payload post dashbord api


export const getCampaignPerformanceReportAction = (tenantId, authToken, userId, payload) => async dispatch => {
    let campaignPerformanceReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReport = await getCampaignPerformanceReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: GET_CAMPAIGN_PERFORMANCE_REPORT,
            campaignPerformanceReport,
        });
        return campaignPerformanceReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignReportFliterListAction = (tenantId, authToken, userId) => async dispatch => {
    let campaignPerformanceReportFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReportFilterList = await getCampaignPerformanceReportFilterListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST,
            campaignPerformanceReportFilterList,
        });
        return campaignPerformanceReportFilterList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSaveReportAction = (tenantId, authToken, userId, type) => async dispatch => {
    let saveReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        saveReport = await getSaveReportApi({ tenantId, authToken, userId, type });
        dispatch({
            type: GET_SAVE_REPORT,
            saveReport,
        });
        return saveReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getUserReachReportAction = (tenantId, authToken, userId, userReachReportReqBody) => async dispatch => {
    let userReachReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        userReachReport = await getUserReachReportApi(tenantId, authToken, userId, userReachReportReqBody);
        dispatch({
            type: GET_USER_REACH_REPORT,
            userReachReport,
        });
        return userReachReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getSaveReportAddAction = (tenantId, authToken, userId, payload) => async dispatch => {
    let saveReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        saveReport = await addSaveReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: GET_SAVE_REPORT,
            saveReport,
        });
        return saveReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSaveReportRefreshAction = (tenantId, authToken, userId, payload, id) => async dispatch => {
    let saveReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        saveReport = await refreshSaveReportApi({ tenantId, authToken, userId, payload, id });
        dispatch({
            type: GET_SAVE_REPORT,
            saveReport,
        });
        return saveReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCPRTrendAction = (tenantId, authToken, userId, campaignPerformanceReportReqBody) => async dispatch => {
    let campaignPerformanceReportTrend = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReportTrend = await getCampaignPerformanceReportApi({ tenantId, authToken, userId, campaignPerformanceReportReqBody });
        dispatch({
            type: GET_CAMPAIGN_PERFORMANCE_REPORT_TREND,
            campaignPerformanceReportTrend,
        });
        return campaignPerformanceReportTrend;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignListAllReportAction = (campaignObj) => async dispatch => {
    let campaignListAllReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignListAllReport = await getCampaignListAllReportApi(campaignObj);
        dispatch({
            type: GET_CAMPAIGN_LIST_ALL_REPORT,
            campaignListAllReport,
        });
        return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignEditIdReportAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await getCampaignEditIdReportApi({ tenantId, authToken, userId, editId });
        return response;
        // dispatch({
        //     type: GET_CAMPAIGN_LIST_ALL_REPORT,
        //     campaignListAllReport,
        // });
        // return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// campaign  Private Market Place
export const getPrivateMarketPlaceAction = (PMPObj) => async dispatch => {
    let privateMarketPlaceList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        privateMarketPlaceList = await getPrivateMarketPlaceApi(PMPObj);
        dispatch({
            type: GET_PRIVATE_MARKET_PLACE_LIST,
            privateMarketPlaceList,
        });
        return privateMarketPlaceList;
        // return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addPrivateMarketPlaceAction = (PMPObj) => async dispatch => {
    let addPrivateMarketPlace = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addPrivateMarketPlace = await addPrivateMarketPlaceApi(PMPObj);
        return addPrivateMarketPlace;
        // dispatch({
        //     type: GET_CAMPAIGN_LIST_ALL_REPORT,
        //     campaignListAllReport,
        // });
        // return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deletePrivateMarketPlaceAction = (PMPObj) => async dispatch => {
    let deletePrivateMarketPlace = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deletePrivateMarketPlace = await deletePrivateMarketPlaceApi(PMPObj);
        return deletePrivateMarketPlace;
        // dispatch({
        //     type: GET_CAMPAIGN_LIST_ALL_REPORT,
        //     campaignListAllReport,
        // });
        // return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



// --------* io list * ------
export const getIoListAllReportAction = (tenantId, authToken, userId) => async dispatch => {
    let ioListAllReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ioListAllReport = await getIoListAllReportApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_IO_LIST_ALL_REPORT,
            ioListAllReport,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addIoReportAction = (tenantId, authToken, userId, addIoReqBody, addParams) => async dispatch => {
    let ioAddReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ioAddReport = await addIoReportApi({ tenantId, authToken, userId, addIoReqBody, addParams });
        return ioAddReport && ioAddReport.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getIoAdvertisersReportAction = (tenantId, authToken, userId) => async dispatch => {
    let ioAdvertisersReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ioAdvertisersReport = await getIoAdvertisersReportApi({ tenantId, authToken, userId, tenantType: { tenantType: 'advertiser' } });
        dispatch({
            type: GET_IO_ADVERTISER_LIST_REPORT,
            ioAdvertisersReport,
        });
        return ioAdvertisersReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getExchangeReportAction = (tenantId, authToken, userId) => async dispatch => {
    let exchangeReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        exchangeReport = await getIoAdvertisersReportApi({ tenantId, authToken, userId, tenantType: { tenantType: 'exchange' } });
        dispatch({
            type: GET_EXCHANGE_LIST_REPORT,
            exchangeReport,
        });
        return (exchangeReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteIoAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteIo = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteIo = await deleteIoApi({ tenantId, authToken, userId, deleteId });
        return deleteIo && deleteIo.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getIoEditIdReportAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let ioEditIdReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ioEditIdReport = await getIoEditIdReportApi({ tenantId, authToken, userId, editId });
        return ioEditIdReport;
        // dispatch({
        //     type: GET_IO_LIST_ALL_REPORT,
        //     ioListAllReport,
        // });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ---- * Country/state/city * ----

export const getCountryAction = (countryObj) => async dispatch => {
    let getCountryList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCountryList = await getCountryListApi(countryObj);
        dispatch({
            type: GET_COUNTRY_LIST,
            getCountryList,
        });
        return getCountryList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// export const getStateAction = (countryCode) => async dispatch => {
//     let getStateList = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         getStateList = await getStateListApi({ countryCode });
//         dispatch({
//             type: GET_STATE_LIST,
//             getStateList,
//         });
//         return getStateList
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const getStateAction = (stateObj) => async dispatch => {
    let getStateList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getStateList = await getStateListApi(stateObj);
        dispatch({
            type: GET_STATE_LIST,
            getStateList,
        });
        return getStateList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// export const getCityAction = (cnscCode) => async dispatch => {
//     let getCityList = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         getCityList = await getCityListApi({ cnscCode });
//         dispatch({
//             type: GET_CITY_LIST,
//             getCityList,
//         });
//         return getCityList;
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };
export const getCityAction = (cityObj) => async dispatch => {
    let getCityList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCityList = await getCityListApi(cityObj);
        dispatch({
            type: GET_CITY_LIST,
            getCityList,
        });
        return getCityList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// state city ( without countryCode/ cnscCode)
export const getStateAllAction = (stateObj) => async dispatch => {
    let getStateAllList = {};
    let getStateAllFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getStateAllList = await getStateListApi(stateObj);
        getStateAllFilterList = groupBy(getStateAllList.responseList, (item) => item.stateName.charAt(0).toLowerCase());
        dispatch({
            type: GET_STATE_LIST_ALL,
            getStateAllList,
            getStateAllFilterList
        });
        return getStateAllList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getCityAllAction = (cityObj) => async dispatch => {
    let getCityAllList = {};
    let getCityAllFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCityAllList = await getCityListApi(cityObj);
        getCityAllFilterList = groupBy(getCityAllList.responseList, (item) => item.cityName.charAt(0).toLowerCase());
        dispatch({
            type: GET_CITY_LIST_ALL,
            getCityAllList,
            getCityAllFilterList
        });
        return getCityAllList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getPincodeAllAction = (pincodeObj) => async dispatch => {
    let getPincodeAllList = {};
    let getPincodeAllFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getPincodeAllList = await getPinCodeListApi(pincodeObj);
        getPincodeAllFilterList = groupBy(getPincodeAllFilterList.responseList, (item) => item.pincode);
        dispatch({
            type: GET_PINCODE_LIST_ALL,
            getPincodeAllList,
            getPincodeAllFilterList
        });
        return getPincodeAllList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



// ----- * Campaign * -----

// export const getCampaignSubCategoryReportAction = (valueId, token) => async dispatch => {
//     let getCampaignSubCategoryReport = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         getCampaignSubCategoryReport = await getCampaignSubCategoryReportApi({ valueId }, token);
//         dispatch({
//             type: GET_CAMPAIGN_SUB_CATEGORY,
//             getCampaignSubCategoryReport,
//         });
//         return getCampaignSubCategoryReport
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const getCampaignSubCategoryReportAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let getCampaignSubCategoryReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCampaignSubCategoryReport = await getCampaignSubCategoryReportApi({ tenantId, authToken, userId, payload, addParams });
        dispatch({
            type: GET_CAMPAIGN_SUB_CATEGORY,
            getCampaignSubCategoryReport,
        });
        return getCampaignSubCategoryReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addCampaignAction = (tenantId, authToken, userId, addCampaignBody, editId) => async dispatch => {
    let campaign = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaign = await addCampaignApi({ tenantId, authToken, userId, addCampaignBody, editId });
        return campaign && campaign.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getPagesFacebookAction = ({tenantId, authToken, userId}) => async dispatch => {
    let pagesData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pagesData = await facebookCampaignPagesApi({ tenantId, authToken, userId});
        return pagesData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampainNamesFacebookAction = ({tenantId, authToken, userId, accountId}) => async dispatch => {
    let campaignNames = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignNames = await facebookCampaignNamesApi({ tenantId, authToken, userId, accountId});
        return campaignNames;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteCampaignAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteCampaign = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteCampaign = await deleteCampaignApi({ tenantId, authToken, userId, deleteId });
        return deleteCampaign && deleteCampaign.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignCategoryTypeAction = (campaignCategoryObj) => async dispatch => {
    let campaignCategoryTypeList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignCategoryTypeList = await getCampaignCategoryTypeApi(campaignCategoryObj);
        dispatch({
            type: GET_CAMPAIGN_CATEGORY_TYPE_LIST,
            campaignCategoryTypeList,
        });
        return campaignCategoryTypeList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ---* creative *---

export const getAdvertiserCreativeGroupAction = (tenantId, authToken, userId, advertiserId) => async dispatch => {
    let getAdvertiserCreativeGroup = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getAdvertiserCreativeGroup = await getAdvertiserCreativeGroupApi(tenantId, authToken, userId, advertiserId);
        dispatch({
            type: GET_ADVERTISER_CREATIVE_GROUP,
            getAdvertiserCreativeGroup,
        });
        return getAdvertiserCreativeGroup;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addCreativeAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let creative = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        creative = await addCreativeApi({ tenantId, authToken, userId, payload, addParams });
        return creative && creative.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const deleteCreativeAction = (creativeObj) => async dispatch => {
    let deleteCreative = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteCreative = await deleteCreativeApi(creativeObj);
        return deleteCreative && deleteCreative.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editCreativeAction = (tenantId, authToken, userId, advertiserId, editId) => async dispatch => {
    let editCreative = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editCreative = await editCreativeApi({ tenantId, authToken, userId, advertiserId, editId });
        return editCreative;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadFileAction = (payload, advertiserId, accept) => async dispatch => {
    console.log("actions", payload);
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadFileActionApi({ payload, advertiserId, accept });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadPlatformFileAction = (payload, advertiserId, accept) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadPlatformFileActionApi({ payload, advertiserId, accept });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
// ---- * Admin * ----

// * Tenant *
export const getTenantAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let tenantListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tenantListAll = await getTenantApi({ tenantId, authToken, userId });
        // return tenantListAll
        dispatch({
            type: GET_TENANT_LIST_ALL,
            tenantListAll,
        });
        return tenantListAll
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addTenantAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addTenant = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addTenant = await addTenantApi({ tenantId, authToken, userId, payload, addParams });
        return addTenant && addTenant.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteTenantAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteTenant = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteTenant = await deleteTenantApi({ tenantId, authToken, userId, deleteId });
        return deleteTenant && deleteTenant;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editTenantAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editTenant = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editTenant = await editTenantApi({ tenantId, authToken, userId, editId });
        return editTenant && editTenant;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



//---- * Supply Partner* --

export const getSupplyPartnerAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let supplyPartnerListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        supplyPartnerListAll = await getSupplyPartnerApi({ tenantId, authToken, userId });
        //   dispatch({
        //         type: GET_SUPPLY_PARTNER_LIST_ALL,
        //         supplyPartnerListAll,
        //     });
        return supplyPartnerListAll;


    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addSupplyPartnerAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addSupplyPartner = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addSupplyPartner = await addSupplyPartnerApi({ tenantId, authToken, userId, payload, addParams });
        return addSupplyPartner && addSupplyPartner.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteSupplyPartnerAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteSupplyPartner = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteSupplyPartner = await deleteSupplyPartnerApi({ tenantId, authToken, userId, deleteId });
        return deleteSupplyPartner && deleteSupplyPartner.responeCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editSupplyPartnerAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editSupplyPartner = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editSupplyPartner = await editSupplyPartnerApi({ tenantId, authToken, userId, editId });
        return editSupplyPartner && editSupplyPartner;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//---- * Feature * --
export const getListFeatureAction = (featureObj, type) => async dispatch => {
    let listFeatureAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listFeatureAll = await getListFeatureApi(featureObj);

        dispatch({
            type: GET_LIST_FEATURE_ALL,
            listFeatureAll,
            responseType: type
        });
        return (listFeatureAll || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getFeatureListAction = (featureObj) => async dispatch => {
    let featurelist = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        featurelist = await getFeatureListApi(featureObj);

        dispatch({
            type: GET_FEATURE_LIST_ALL,
            featurelist,
        });
        return (featurelist || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addFeatureAction = (featureObj) => async dispatch => {
    let addfeature = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addfeature = await addFeatureApi(featureObj);
        return (addfeature || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addFeatureAttributeAction = (featureObj) => async dispatch => {
    let addFeatureAttribute = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addFeatureAttribute = await addFeatureAttributeApi(featureObj);
        return (addFeatureAttribute || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



// --- * Demand Partner Seat * --
export const getDemandPartnerSeatListAction = (demandObj) => async dispatch => {
    let demandpartnerSeatList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        demandpartnerSeatList = await getDemandpartnerSeatListApi(demandObj);
        dispatch({
            type: GET_DEMAND_PARTNER_SEAT_LIST_ALL,
            demandpartnerSeatList,
        });
        return demandpartnerSeatList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//------* Currency Rate *------------
export const getCurrencyRateListAction = (demandObj) => async dispatch => {
    let currencyRateList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        currencyRateList = await getCurrencyRateListApi(demandObj);
        dispatch({
            type: GET_CURRENCY_RATE_LIST,
            currencyRateList,
        });
        return currencyRateList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const addCurrencyRateAction = (tenantId, authToken, userId, form) => async dispatch => {
    let addRate = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addRate = await addCurrencyRateApi(tenantId, authToken, userId, form);
        dispatch({
            type: ADD_CURRENCY_RATE,
            addRate,
        });
        return addRate;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//---- * Demand Partner * --

export const getDemandPartnerListAction = (demandObj) => async dispatch => {
    let demandpartnerList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        demandpartnerList = await getDemandpartnerListApi(demandObj);
        dispatch({
            type: GET_DEMAND_PARTNER_LIST_ALL,
            demandpartnerList,
        });
        return demandpartnerList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addDemandPartnerAction = (demandPartnerObj) => async dispatch => {
    let addDemandPartner = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addDemandPartner = await addAdminDemandPartnerApi(demandPartnerObj);
        return addDemandPartner;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getDemandPartnerNameFilterAction = (demandObj) => async dispatch => {
    let demandPartnerNameList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        demandPartnerNameList = await getDemandPartnerNamesFilterApi(demandObj);

        dispatch({
            type: GET_DEMAND_PARTNER_NAME_LIST_ALL,
            demandPartnerNameList,
        });
        return demandPartnerNameList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getTenantDemandPartnerListAction = (demandObj) => async dispatch => {
    let tenantDemandpartnerList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tenantDemandpartnerList = await getTenantDemandpartnerListApi(demandObj);
        dispatch({
            type: GET_TENANT_DEMAND_PARTNER_LIST,
            tenantDemandpartnerList,
        });
        return tenantDemandpartnerList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRoleAccessPermissions = (permissionObj) => async dispatch => {
    let getUserPermissionList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        let data = await roleAccessPermissions(permissionObj);
        let permissionsList = [];
        data.responseList.forEach((a) => {
            if (a.roleDetails && a.roleDetails.length) {
                a.roleDetails.forEach((b) => {
                    permissionsList.push((`${b.moduleName}-${b.featureName}-${b.useCaseName}-${b.bcrumName}`.toUpperCase()).trim());
                });
            }
        });
        getUserPermissionList = uniq(permissionsList);

        let defaultMainRoute = '';
        // default route dashboard
        let defaultRoute = {};
        let dashboard = MODULE_PERMISSIONS.DASHBOARD.find(a => getUserPermissionList.indexOf(a) > -1);
        if (dashboard && ROUTE[dashboard]) {
            defaultRoute.dashboard = ROUTE[dashboard];
            defaultMainRoute = ROUTE[dashboard];
        }

        // default route mediaplan
        let mediaPlan = MODULE_PERMISSIONS.MEDIAPLAN.find(a => getUserPermissionList.indexOf(a) > -1);
        if (mediaPlan && ROUTE[mediaPlan]) {
            defaultRoute.mediaPlan = ROUTE[mediaPlan];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[mediaPlan];
            }
        }

        // default route Admin Margin
        let adminMargin = MODULE_PERMISSIONS.ADMINMARGIN.find(a => getUserPermissionList.indexOf(a) > -1);
        if (adminMargin && ROUTE[adminMargin]) {
            defaultRoute.adminMargin = ROUTE[adminMargin];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[adminMargin];
            }
        }

        // default route  Margin
        let margin = MODULE_PERMISSIONS.MARGIN.find(a => getUserPermissionList.indexOf(a) > -1);
        if (margin && ROUTE[margin]) {
            defaultRoute.margin = ROUTE[margin];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[margin];
            }
        }

        // default route  RO
        let ro = MODULE_PERMISSIONS.RO.find(a => getUserPermissionList.indexOf(a) > -1);
        if (ro && ROUTE[ro]) {
            defaultRoute.ro = ROUTE[ro];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[ro];
            }
        }

        // default route  UNMAPPEDCAMPAIGN
        let unmappedCampaign = MODULE_PERMISSIONS.UNMAPPEDCAMPAIGN.find(a => getUserPermissionList.indexOf(a) > -1);
        if (unmappedCampaign && ROUTE[unmappedCampaign]) {
            defaultRoute.unmappedCampaign = ROUTE[unmappedCampaign];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[unmappedCampaign];
            }
        }

        // default route  OPTIZER
        let optimizer = MODULE_PERMISSIONS.OPTIMIZER.find(a => getUserPermissionList.indexOf(a) > -1);
        if (optimizer && ROUTE[optimizer]) {
            defaultRoute.optimizer = ROUTE[optimizer];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[optimizer];
            }
        }




        // default route campaign
        let campaign = MODULE_PERMISSIONS.CAMPAIGN.find(a => getUserPermissionList.indexOf(a) > -1);
        if (campaign && ROUTE[campaign]) {
            defaultRoute.campaign = ROUTE[campaign];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[campaign];
            }
        }



        // default route admin
        let admin = MODULE_PERMISSIONS.ADMIN.find(a => getUserPermissionList.indexOf(a) > -1);
        if (admin && ROUTE[admin]) {
            defaultRoute.admin = ROUTE[admin];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[admin];
            }
        }


        localStorage.setItem('getUserPermissionList', JSON.stringify(getUserPermissionList));
        localStorage.setItem('defaultRoute', JSON.stringify(defaultRoute));
        localStorage.setItem('defaultMainRoute', JSON.stringify(defaultMainRoute));

        dispatch({
            type: GET_USER_PERMISSION,
            getUserPermissionList,
            defaultRoute,
            defaultMainRoute
        });
        return { getUserPermissionList, data, defaultMainRoute };
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ---- * Audience * ----

// * Apr *
export const getAprAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let aprListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        aprListAll = await getAprApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_APR_LIST_ALL,
            aprListAll,
        });
        return aprListAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getSegmentListAction = ({ tenantId, authToken, userId, params, Addparams }) => async dispatch => {
    let getSegmentList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getSegmentList = await getSegmentListApi({ tenantId, authToken, userId, params, Addparams });
        dispatch({
            type: GET_SEGMENT_LIST_ALL,
            getSegmentList,
        });
        return getSegmentList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getHierarchyListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let getHierarchyList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getHierarchyList = await getHierarchyListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_HIERARCHY_LIST_ALL,
            getHierarchyList,
        });
        return getHierarchyList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addAprAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addApr = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addApr = await addAprApi({ tenantId, authToken, userId, payload, addParams });
        return addApr && addApr.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAprEditAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let aprEditReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        aprEditReport = await getAprEditApi({ tenantId, authToken, userId, editId });
        return aprEditReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const aprDownloadAction = (tenantId, authToken, userId, aprId) => async dispatch => {
    let aprDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        aprDownload = await aprDownloadApi({ tenantId, authToken, userId, aprId });
        return aprDownload;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addHierarchyAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addHierarchy = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addHierarchy = await addHierarchyApi({ tenantId, authToken, userId, payload, addParams });
        return addHierarchy && addHierarchy.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteHierarchyAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteHierarchyData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteHierarchyData = await deleteHierarchyApi({ tenantId, authToken, userId, deleteId });
        return deleteHierarchyData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addSegmentAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addSegment = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addSegment = await addSegmentApi({ tenantId, authToken, userId, payload, addParams });
        return addSegment && addSegment.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getDataPixelEditAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let dataPixelEditReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dataPixelEditReport = await getDataPixelEditApi({ tenantId, authToken, userId, editId });
        return dataPixelEditReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// Data Capture

export const addDataCaptureAction = (dataCaptureObj) => async dispatch => {
    let addDataCapture = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addDataCapture = await addDataCaptureApi(dataCaptureObj);
        return (addDataCapture || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getDataCaptureAction = (dataCaptureObj) => async dispatch => {
    let getDataCapture = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getDataCapture = await getDataCaptureApi(dataCaptureObj);
        return (getDataCapture || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getDataCaptureListAction = (dataCaptureObj) => async dispatch => {
    let getDataCaptureList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getDataCaptureList = await getDataCaptureListApi(dataCaptureObj);
        dispatch({ type: GET_DATA_CAPTURE_LIST_ALL, getDataCaptureList });
        return (getDataCaptureList || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const deleteDataCaptureAction = (dataCaptureObj) => async dispatch => {
    let deleteDataCapture = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteDataCapture = await deleteDataCaptureApi(dataCaptureObj);
        return (deleteDataCapture || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSegmentEditAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let segmentEditReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        segmentEditReport = await getsegmentEditApi({ tenantId, authToken, userId, editId });
        return segmentEditReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// audiance Hierarchy
export const audienceHierarchylistAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let audienceHierarchyList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        audienceHierarchyList = await getAudhierarchyListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_AUDIENCE_HIERARCHY_LIST_ALL,
            audienceHierarchyList,
        });
        return audienceHierarchyList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// audiance Hierarchy
export const downloadingConversionTagAction = (dataConversionTagObj) => async dispatch => {
    let downloadingConversionTag = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        downloadingConversionTag = await downloadingConversionTagApi(dataConversionTagObj);
        return (downloadingConversionTag || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// get audiance pixel micros
export const getAudiancePixelMicrosAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let getAudiancePixelMicros = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getAudiancePixelMicros = await getAudiancePixelMicrosApi({ tenantId, authToken, userId });
        return (getAudiancePixelMicros || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// check unique tag and postback name

export const checkTagAndPostbackuniqueAction = ({ tenantId, authToken, userId, checkParam }) => async dispatch => {

    let checkTagPostbackName = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        checkTagPostbackName = await checkTagAndPostbackuniqueApi({ tenantId, authToken, userId, checkParam });
        return (checkTagPostbackName || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// upload jstag script action
export const uploadScriptAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadScriptApi({ tenantId, authToken, userId, payload });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// get add tenant pin code action
export const getPinCodeAction = (pincodeObj) => async dispatch => {

    let getPincodeList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getPincodeList = await getPinCodeListApi(pincodeObj);
        dispatch({
            type: GET_PINCODE_LIST,
            getPincodeList,
        });
        return getPincodeList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// forgot Password

export const forgotPasswordAction = (authUser) => async dispatch => {
    let forgotPassword = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        forgotPassword = await forgotPasswordApi(authUser);
        return (forgotPassword || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const validateResetPassword = ({ rp }) => async dispatch => {
    let validateResetPassword = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        validateResetPassword = await validateResetPasswordApi({ rp });
        return (validateResetPassword || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const changePasswordAction = ({ password, rp }) => async dispatch => {
    let changePassword = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        changePassword = await changePasswordApi({ password, rp });
        return (changePassword || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// campaign screen site list
// * Apr *
export const getCampaignSiteAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let getCampaignSiteAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCampaignSiteAll = await getCampaignSiteApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_CAMPAIGN_SITE_LIST_ALL,
            getCampaignSiteAll,
        });
        return getCampaignSiteAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadSiteFileAction = (payload) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadSiteFileApi({ payload });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addCampaignsiteAction = ({ tenantId, authToken, userId, payload, addParams }) => async dispatch => {
    let addCampaignSite = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addCampaignSite = await addCampaignSiteApi({ tenantId, authToken, userId, payload, addParams });
        return addCampaignSite;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteCampaignSiteAction = ({ tenantId, authToken, userId, deleteId }) => async dispatch => {
    let deleteCampaignSite = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteCampaignSite = await deleteCampaignSiteApi({ tenantId, authToken, userId, deleteId });
        return deleteCampaignSite && deleteCampaignSite.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// eslint-disable-next-line no-unused-vars
export const uploadBulkFileAction = (payload, advertiserId, accept) => async dispatch => {
    let response = {};
    try {
        // dispatch({
        //     type: BEGIN_AJAX_CALL,
        //     showSpinner: true,
        // });
        response = await uploadFileActionApi({ payload, advertiserId, accept });
        return response;
    } catch (err) {
    } finally {
        // dispatch({
        //     type: END_AJAX_CALL,
        //     showSpinner: false,
        // });
    }
};

export const getCampaignPerformanceReportDownloadAction = (tenantId, authToken, userId, campaignPerformanceReportReqBody) => async dispatch => {
    let campaignPerformanceReportDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReportDownload = await getCampaignPerformanceReportDownloadApi({ tenantId, authToken, userId, campaignPerformanceReportReqBody });
        return (campaignPerformanceReportDownload || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getUserReachReportDownloadAction = (tenantId, authToken, userId, userReachReportReqBody) => async dispatch => {
    let userReachReportDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        userReachReportDownload = await getUserReachReportDownloadApi({ tenantId, authToken, userId, userReachReportReqBody });
        return (userReachReportDownload || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const getBidderReportDownloadAction = (tenantId, authToken, userId, bidderReportReqBody) => async dispatch => {
    let bidderDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        bidderDownloadReport = await getBidderReportDownloadApi({ tenantId, authToken, userId, bidderReportReqBody });
        return (bidderDownloadReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getMediaPlanningDownloadReportAction = (tenantId, authToken, userId, mediaPlanningReportReqBody) => async dispatch => {
    let mediaPlanningDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaPlanningDownloadReport = await getMediaPlanningDownloadReportApi({ tenantId, authToken, userId, mediaPlanningReportReqBody });
        return (mediaPlanningDownloadReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getMediaReportDownloadAction = (props) => async dispatch => {
    let mediaReportDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaReportDownload = await getMediaReportDownloadApi(props);
        return (mediaReportDownload || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRevenueReportDownloadAction = (tenantId, authToken, userId, revenueReportReqBody) => async dispatch => {
    let revenueDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        revenueDownloadReport = await getRevenueReportDownloadApi({ tenantId, authToken, userId, revenueReportReqBody });
        return (revenueDownloadReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getQPSReportDownloadAction = (tenantId, authToken, userId, qpsReportReqBody) => async dispatch => {
    let qpsDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        qpsDownloadReport = await getQpsReportDownloadApi({ tenantId, authToken, userId, qpsReportReqBody });
        return (qpsDownloadReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getCampaignPixelListAction = (tenantId, authToken, userId, advertiserId) => async dispatch => {
    let campaignPixelList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPixelList = await getCampaignPixelListApi(tenantId, authToken, userId, advertiserId);
        dispatch({
            type: GET_CAMPAIGN_PIXEL_LIST,
            campaignPixelList,
        });
        return (campaignPixelList || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//delete segment

export const deleteSegmentAction = (segmentObj) => async dispatch => {
    let deleteSegment = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteSegment = await deleteSegmentApi(segmentObj);
        return (deleteSegment || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getAccountsListAction = (tenantId, authToken, userId) => async dispatch => {
    let accountsList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        accountsList = await getAccountsListApi(tenantId, authToken, userId);
        dispatch({
            type: GET_ACCOUNTS_LIST,
            accountsList,
        });
        return accountsList
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const addAccountAction = (tenantId, authToken, userId, payload, addParams, id) => async dispatch => {
    let addAccount = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addAccount = await addAccountApi({ tenantId, authToken, userId, payload, addParams, id });
        return addAccount;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const editAccountAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editAccount = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editAccount = await editAccountApi({ tenantId, authToken, userId, editId });
        return editAccount && editAccount;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editUpTenantAction = (tenantId, authToken, userId, data, addParams, id, type) => async dispatch => {
    let check = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        check = await editUpTenantApi(tenantId, authToken, userId, data, addParams, id, type);
        dispatch({
            type: ADD_TENANT,
            check,
        });
        return check;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const addUpTenantAction = (tenantId, authToken, userId, response, data, type) => async dispatch => {
    let check = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        check = await addUpTenantApi(tenantId, authToken, userId, response, data, type);
        dispatch({
            type: ADD_TENANT,
            check,
        });
        return check;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const showSpinnerAction = (showSpinner = false) => async dispatch => {
    dispatch({ type: BEGIN_AJAX_CALL, showSpinner: showSpinner });
};

export const addPlatformAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addPlatform = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addPlatform = await addPlatformApi({ tenantId, authToken, userId, payload, addParams });
        return addPlatform && addPlatform.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editPlatformAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editPlatform = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editPlatform = await editPlatformApi({ tenantId, authToken, userId, editId });
        return editPlatform;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getPlatformListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let platformListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        platformListAll = await getPlatformListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_PLATFORM_LIST_ALL,
            platformListAll,
        });
        return platformListAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ATD API STARTS HERE ********************************************















export const getListAdvertiserAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let advertiserList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        advertiserList = await getListAdvertiserApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ADVERTISER_LIST,
            advertiserList,
        });
        return advertiserList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const campaignPerformanceReportAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let campaignPerformanceList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceList = await campaignPerformanceReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CAMPAIGN_PERFORMACE_REPORT,
            campaignPerformanceList,
        });
        return campaignPerformanceList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const campaignPerformanceTotalReportAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let campaignPerformanceTotal = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceTotal = await campaignPerformanceTotalReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CAMPAIGN_PERFORMACE_TOTAL_REPORT,
            campaignPerformanceTotal,
        });
        return campaignPerformanceTotal;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const campaignPerformanceReportDatewiseAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let campaignPerformanceDateWise = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceDateWise = await campaignPerformanceReportDatewiseApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT,
            campaignPerformanceDateWise,
        });
        return campaignPerformanceDateWise;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const campaignPerformanceReportDatewiseDownloadAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let csvListData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        csvListData = await campaignPerformanceReportDatewiseDownloadApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT_DOWNLOAD,
            csvListData,
        });
        return csvListData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postChannelSpendAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let channelSpend = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        channelSpend = await postChannelSpendApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CHANNEL_SPEND,
            channelSpend,
        });
        // console.log(channelSpend,"ACTIOn");
        return channelSpend;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const creativeDetailsAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let creativeDetailsList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        creativeDetailsList = await creativeDetailsApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_CREATIVE_DETAILS,
            creativeDetailsList,
        });
        return creativeDetailsList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const domainDetailsAction = ({ tenantId, authToken, userId, payload, campaignId, params, Addparams }) => async dispatch => {
    // console.log("action", campaignId);
    // console.log("params$$$$$$", params);

    let domainReportList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        domainReportList = await domainReportApi({ tenantId, authToken, userId, payload, campaignId, params, Addparams });
        dispatch({
            type: DOMAIN_REPORT,
            domainReportList,
        });
        return domainReportList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};





export const demoGraphicReportDownloadAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    // console.log("action", campaignID);
    let demoGraphicReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        demoGraphicReport = await demoGraphicReportDownloadApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: DEMO_GRAPHIC_DOWNLOAD_REPORT,
            demoGraphicReport,
        });
        return demoGraphicReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// Age Report download function here
export const ageDataReportDownloadAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    // console.log("action", campaignID);
    let ageReportsData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ageReportsData = await ageDataReportDownloadApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: AGE_REPORT_DOWNLOAD_REPORT,
            ageReportsData,
        });
        return ageReportsData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// Gender Report data functon here 

export const genderDataReportDownloadAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    // console.log("action", campaignID);
    let genderReportsData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        genderReportsData = await genderDataReportDownloadApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: GENDER_REPORT_DOWNLOAD_REPORT,
            genderReportsData,
        });
        return genderReportsData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};





export const deviceReportAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    // console.log("action", campaignID);
    let deviceReportData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deviceReportData = await deviceReportApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: DEVICE_REPORT,
            deviceReportData,
        });
        return deviceReportData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deviceReportDownloadAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    // console.log("action", campaignID);
    let deviceReportDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deviceReportDownload = await deviceReportDownloadApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: DEVICE_REPORT_DOWNLOAD,
            deviceReportDownload,
        });
        return deviceReportDownload;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};






export const postGeoReportAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let geoReportList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        geoReportList = await postGeoReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_GEO_REPORT,
            geoReportList,
        });
        // console.log(geoReportList,"ACTIOn");
        return geoReportList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const postGeoCampaignAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let geoCampaignReportList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        geoCampaignReportList = await postGeoCampaignIdApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_GEO_CAMPAIGN_ID_REPORT,
            geoCampaignReportList,
        });
        return geoCampaignReportList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const postCreativeReportTableAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let creativeReportTableData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        creativeReportTableData = await postCreativeReportTableApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_CREATIVE_REPORT_TABLE,
            creativeReportTableData,
        });
        return creativeReportTableData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const postCreativeReportAction = ({ tenantId, authToken, userId, payload, offset, pageLimit }) => async dispatch => {
    let creativeAllReportList = {};
    // console.log(offset, "ACTIOn ");     
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        creativeAllReportList = await postCreativeReportApi({ tenantId, authToken, userId, payload, offset, pageLimit });
        dispatch({
            type: POST_CREATIVE_PERFORMANCE_REPORT,
            creativeAllReportList,
        });
        return creativeAllReportList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const postAgeGenderReportAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let ageGenderAllReportList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ageGenderAllReportList = await postAgeGenderReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_AGE_GENDER_REPORT,
            ageGenderAllReportList,
        });
        return ageGenderAllReportList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// Age Graph Report Action Function


export const postAgeGraphReportAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let ageAllReportListData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ageAllReportListData = await postAgeGraphReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_AGE_REPORT,
            ageAllReportListData,
        });
        return ageAllReportListData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// Gender Graph Report Action Function

export const postGenderGraphReportAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let genderAllReportListData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        genderAllReportListData = await postGenderGraphReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_GENDER_REPORT,
            genderAllReportListData,
        });
        return genderAllReportListData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const postRecoImplementationReportAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let recoImplementation = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        recoImplementation = await postRecoImplementationApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: RECO_IMPLEMENTATION,
            recoImplementation,
        });
        return recoImplementation;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const postAgeGenderCampaignIdAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let ageGenderCampaignIdReportList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ageGenderCampaignIdReportList = await postAgeGenderCampaignIdApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_AGE_GENDER_CAMPAIGN_ID,
            ageGenderCampaignIdReportList,
        });
        return ageGenderCampaignIdReportList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// Seprate age graph starts here

export const postAgeGraphCampaignIdAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let perticularAgeCampaignData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        perticularAgeCampaignData = await postAgeGraphCampaignIdApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_AGE_CAMPAIGN_ID,
            perticularAgeCampaignData,
        });
        return perticularAgeCampaignData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// Seprate gender graph function here


export const postGenderGraphCampaignIdAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let perticularGenderCampaignData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        perticularGenderCampaignData = await postGenderGraphCampaignIdApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_GENDER_CAMPAIGN_ID,
            perticularGenderCampaignData,
        });
        return perticularGenderCampaignData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};






// ENDS HERE ATD ***********************************************************


export const getPlatformAction = ({ tenantId, authToken, userId, id }) => async dispatch => {
    let platformData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        platformData = await getPlatformSingleListApi({ tenantId, authToken, userId, id });
        return platformData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const startSpinner = () => async dispatch => {
    dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
    });
}

export const stopSpinner = () => async dispatch => {
    dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
    });
}

// ======= advertiser action type
export const createAdvertiserAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let createAdvertiser = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        createAdvertiser = await createAdvertiserApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: CREATE_ADVERTISER,
            createAdvertiser,
        });
        return createAdvertiser;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const listAdvertiserAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let listAdvertiser = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listAdvertiser = await listAdvertiserApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: ADVERTISER_LIST,
            listAdvertiser,
        });
        return listAdvertiser;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getEditListAdvertiserAction = ({ tenantId, authToken, userId, payload, advertiserId }) => async dispatch => {
    // console.log("advertiserId", advertiserId);
    let editListAdvertiser = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editListAdvertiser = await editAdvertiserListApi({ tenantId, authToken, userId, payload, advertiserId });
        dispatch({
            type: EDIT_ADVERTISER_LIST,
            editListAdvertiser,
        });
        return editListAdvertiser;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const updateAdvertiserAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let updateAdvertiser = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateAdvertiser = await updateAdvertiserApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: UPDATE_ADVERTISER,
            updateAdvertiser,
        });
        return updateAdvertiser;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getAdvertisersWithoutAccounts = ({ tenantId, authToken, userId }) => async dispatch => {
    let advertisersWithoutAccounts = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        advertisersWithoutAccounts = await getAdvertiserWithoutAccApi({ tenantId, authToken, userId });
        dispatch({
            type: ADVERTISERS_WITHOUT_ACCOUNTS,
            advertisersWithoutAccounts,
        });
        return advertisersWithoutAccounts;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




// 8****************************MediaPlan Action start here


// export const postMediaPlanCreationAction = (tenantId, authToken, userId, payload) => async dispatch => {
//     console.log(payload, "action");

//     let mediaplancreationData = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         mediaplancreationData = await postMediaPlanCreationApi({ tenantId, authToken, userId, payload });
//         dispatch({
//             type: POST_MEDIA_PLAN_CREATION,
//             mediaplancreationData,
//         });
//         return mediaplancreationData;
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };


export const postMediaCreateAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    // console.log("actions", payload);s
    let postDetailsMedia = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        postDetailsMedia = await postMediaPlanApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_MEDIA_CREATE_ACTION,
            postDetailsMedia,
        });
        return postDetailsMedia;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};











export const getMediaPlanListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let mediaPlanList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaPlanList = await getMediaPlanListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_MEDIA_PLAN_LIST,
            mediaPlanList,
        });
        return mediaPlanList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getAgeListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let ageList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ageList = await getAgeListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_AGE_LIST,
            ageList,
        });
        return ageList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCategoryAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let categoryList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        categoryList = await getCategoryApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_CATEGORY_LIST,
            categoryList,
        });
        return categoryList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const getSUbCategoryAction = ({ tenantId, authToken, cateID, userId }) => async dispatch => {
    let subCategoryList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        subCategoryList = await getSUbCategoryApi({ tenantId, authToken, userId, cateID });
        dispatch({
            type: GET_SUBCATEGORY_LIST,
            subCategoryList,
        });
        return subCategoryList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




// =========== end advertiser action type


// *************************** OPTIMIZER SCREEN STARTS HERE*************************

export const getOptimizerListAction = ({ tenantId, authToken, userId,params={},payload={} }) => async dispatch => {
    let optimizerList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        optimizerList = await getOptimizerListApi({ tenantId, authToken, userId,params,payload });
        dispatch({
            type: GET_OPTIMIZER_LIST,
            optimizerList,
        });
        return optimizerList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getOptimizerStatusAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let optimizerStatus = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        optimizerStatus = await getOptimizerStatusApi({ tenantId, authToken, userId });

        return optimizerStatus;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const getOptimizerListRecommendationAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let optimizerRecommendatioList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            // showSpinner: true,
        });
        optimizerRecommendatioList = await getOptimizerListRecommendationApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_OPTIMIZER_RECOMMENDATION_LIST,
            optimizerRecommendatioList,
        });
        return optimizerRecommendatioList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            // showSpinner: false,
        });
    }
};

export const getTenantOptimizerListRecommendationAction = ({ tenantId, authToken, userId,payload={} }) => async dispatch => {
    let optimizerRecommendationList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            // showSpinner: true,
        });
        optimizerRecommendationList = await getOptimizerListRecommendationApiForSelectedTenant({ tenantId, authToken, userId, payload });
        return optimizerRecommendationList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            // showSpinner: false,
        });
    }
}



export const getRecommendationAction = ({ tenantId, authToken, userId, roID }) => async dispatch => {
    let recommedationListData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        recommedationListData = await getRecommendationApi({ tenantId, authToken, userId, roID });
        dispatch({
            type: GET_RECOMMENDATION_LIST,
            recommedationListData,
        });
        return recommedationListData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const postUpdateRecommendationAction = ({ tenantId, authToken, userId, payload,params={} }) => async dispatch => {
    let postUpdateData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        postUpdateData = await postUpdateRecommendationApi({ tenantId, authToken, userId, payload,params });
        dispatch({
            type: POST_UPDATE_RECOMMENDATION,
            postUpdateData,
        });
        return postUpdateData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postDeleteRecommendationAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let postUpdateData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        postUpdateData = await postDeleteRecommendationApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_DELETE_RECOMMENDATION,
            postUpdateData,
        });
        return postUpdateData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

















// ******************************** OPTIMIZER SCREEN ENDS HERE ***************************

// ================= start RO api actions ================
export const getPrimaryGoalAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let primaryGoal = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        primaryGoal = await getPrimaryGoalApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_PRIMARY_GOAL,
            primaryGoal,
        });
        return primaryGoal;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSecondaryGoalAction = ({ tenantId, authToken, primaryGoalID, userId }) => async dispatch => {
    let secondaryGoal = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        secondaryGoal = await getSecondaryGoalApi({ tenantId, authToken, primaryGoalID, userId });
        dispatch({
            type: GET_SECONDARY_GOAL,
            secondaryGoal,
        });
        return secondaryGoal;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const createRoAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    console.log("action", tenantId, userId);
    let createRO = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        createRO = await createRoApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: CREATE_RO,
            createRO,
        });
        return createRO;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const roListAction = ({ tenantId, authToken, userId,payload={} }) => async dispatch => {
    let roList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        roList = await getRoListApi({ tenantId, authToken, userId,payload });
        dispatch({
            type: FETCH_RO_LIST,
            roList,
        });
        return roList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const campaignMappedRoAction = ({ tenantId, authToken, userId,roID=-1 }) => async dispatch => {
    let roList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        roList = await getRoMppedCampaignList({ tenantId, authToken, userId,roID });
        dispatch({
            type: FETCH_RO_LIST,
            roList,
        });
        return roList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const roStatusUpdateAction = ({ tenantId, authToken, userId, payload = {} }) => async dispatch => {
    let status = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        status = await postUpdateStatusRo({ tenantId, authToken, userId, payload });
        return status;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const downloadRoListAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let status = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        status = await downloadRoList({ tenantId, authToken, userId, payload });
        return status;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleleMappedWithCampaignAction = ({ tenantId, authToken, userId, params={} }) => async dispatch => {
    let status = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        status = await deleteMappedCampaignWithRo({ tenantId, authToken, userId, params });
        return status;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postCampaignRoListAction = ({ tenantId, authToken, userId, payload={} }) => async dispatch => {
    let campList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campList = await postCampaignListNamesWithRo({ tenantId, authToken, userId, payload });
        return campList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCountUnMappedCampaignAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let countUnmapped = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        countUnmapped = await getCountUnmappedCampaign({ tenantId, authToken, userId });
        dispatch({
            type: GET_UNMAPPED_CAMPAIGN_COUNT,
            countUnmapped,
        });
        return countUnmapped;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const roListNamesAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let roNamesList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        roNamesList = await getRoListNamesApi({ tenantId, authToken, userId });
        dispatch({
            type: FETCH_RO_LIST_NAMES,
            roNamesList,
        });
        return roNamesList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRoListByIdAction = ({ tenantId, authToken, userId, roId }) => async dispatch => {
    let listById = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listById = await getRoListByIdApi({ tenantId, authToken, userId, roId });
        dispatch({
            type: GET_RO_LIST_BY_ID,
            listById,
        });
        return listById;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const updateRoAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let updateRO = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateRO = await updateRoApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: UPDATE_RO,
            updateRO,
        });
        return updateRO;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ================= end RO actions ================



// *************************** UNMAPPED CAMAPAIGN STARTS HERE*********************************

export const getUnmappedCampaignListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let unmappedCampaignList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        unmappedCampaignList = await getUnmappedCampaignListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_UNMAPPED_CAMPAIGN_LIST,
            unmappedCampaignList,
        });
        return unmappedCampaignList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postUnmappedCampaignListAction = ({ tenantId, authToken, userId, payload={} }) => async dispatch => {
    let unmappedCampaignList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        unmappedCampaignList = await postUnmappedCampaignListApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: GET_UNMAPPED_CAMPAIGN_LIST,
            unmappedCampaignList,
        });
        return unmappedCampaignList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const createMapRoAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let createMapRO = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        createMapRO = await createMapRoApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: CREATE_MAP_RO,
            createMapRO,
        });
        return createMapRO;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const mapExistingRoAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let mapExistingRO = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mapExistingRO = await mapExistingRo({ tenantId, authToken, userId, payload });
        dispatch({
            type: MAP_EXISTING_RO,
            mapExistingRO,
        });
        return mapExistingRO;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// *************************** UNMAPPED CAMPAIGN END HERE*****************************************


// ====================== campaign audience api ==============================

export const getGoogleAudienceAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let googleAudience = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        googleAudience = await getGoogleAudienceList({ tenantId, authToken, userId });
        dispatch({
            type: GOOGLE_AUDIENCE_LIST,
            googleAudience,
        });
        return googleAudience;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getDv360AudienceAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let dv360Audience = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dv360Audience = await getDv360AudienceList({ tenantId, authToken, userId });
        dispatch({
            type: DV360_AUDIENCE_LIST,
            dv360Audience,
        });
        return dv360Audience;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getFacebookAudienceAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let facebookAudience = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        facebookAudience = await getFacebookAudienceList({ tenantId, authToken, userId });
        dispatch({
            type: FACEBOOK_AUDIENCE_LIST,
            facebookAudience,
        });
        return facebookAudience;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// =========== CAMPAIGN API IMPLEMETAION ===============

export const createCampaignAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let campaign = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaign = await createCampaignApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: CAMPAIGN_CREATION,
            campaign,
        });
        return campaign;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getCampaignListByIdAction = ({ tenantId, authToken, userId, campaignId }) => async dispatch => {
    let campaignLIstById = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignLIstById = await campaignListById({ tenantId, authToken, userId, campaignId });
        dispatch({
            type: CAMPAIGN_LIST_BY_ID,
            campaignLIstById,
        });
        return campaignLIstById;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const postCampaignListAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let campaignList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignList = await postCampaignList({ tenantId, authToken, userId, payload });
        dispatch({
            type: CAMPAIGN_LIST,
            campaignList,
        });
        return campaignList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const updateCampaignStatusAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let updateStatus = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateStatus = await updateCampaignStatusApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: UPDATE_CAMPAIGN_STATUS,
            updateStatus,
        });
        return updateStatus;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// , 


export const updateCampaignAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let updateCampaign = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateCampaign = await updateCampaignApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: UPDATE_CAMPAIGN,
            updateCampaign,
        });
        return updateCampaign;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
















// **********************************ADMIN ,MARGIN  STARTS HERE ***************************************



export const getAdvertiserListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let adminMarginData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminMarginData = await getAdvertiserListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ADVERTISER_LIST_ADMINMARGIN,
            adminMarginData,
        });
        return adminMarginData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getAllCampaignListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let campListData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campListData = await getAllCampaignListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_CAMPAIGN_LIST_ADMINMARGIN,
            campListData,
        });
        return campListData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const getAllListAdminMarginAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let listAdmin = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listAdmin = await getAllListAdminMarginApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ALL_LIST_ADMIN_MARGIN,
            listAdmin,
        });
        return listAdmin;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postCreationAdminMarginAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let adminMarginData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminMarginData = await postCreationAdminMarginApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CREATE_ADMIN_MARGIN,
            adminMarginData,
        });
        return adminMarginData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const getListByIdAdminMarginAction = ({ tenantId, authToken, userId, roId }) => async dispatch => {
    let listByIdAdminMargin = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listByIdAdminMargin = await getListByIdAdminMarginApi({ tenantId, authToken, userId, roId });
        dispatch({
            type: GET_ADMIN_MARGIN_LIST_BY_ID,
            listByIdAdminMargin,
        });
        return listByIdAdminMargin;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const postUpdatetionAdminMarginAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let updateAdminMarginData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateAdminMarginData = await postUpdatetionAdminMarginApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_UPDATE_ADMIN_MARGIN,
            updateAdminMarginData,
        });
        return updateAdminMarginData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};





export const getPlaformWiseAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let platformWiseData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        platformWiseData = await getPlaformWiseApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_PLATFORM_WISE_DATA,
            platformWiseData,
        });
        return platformWiseData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};












// **********************************ADMIN MARGIN  ENDS HERE ***************************************




// ********************************** MARGIN  STARTS  HERE ***************************************>>>>>




export const getAllListMarginAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let listMargin = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listMargin = await getAllListMarginApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ALL_LIST_MARGIN,
            listMargin,
        });
        return listMargin;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const postCreationMarginAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let createMarginData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        createMarginData = await postCreationMarginApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CREATE_MARGIN,
            createMarginData,
        });
        return createMarginData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// list by id


export const getListByIdMarginAction = ({ tenantId, authToken, userId, roId }) => async dispatch => {
    let listByIdAdminMargin = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listByIdAdminMargin = await getListByIdMarginApi({ tenantId, authToken, userId, roId });
        dispatch({
            type: GET_MARGIN_LIST_BY_ID,
            listByIdAdminMargin,
        });
        return listByIdAdminMargin;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const postUpdatetionMarginAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let updateMarginData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateMarginData = await postUpdatetionMarginApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_UPDATE_MARGIN,
            updateMarginData,
        });
        return updateMarginData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
// ********************************** MARGIN  ENDs  HERE ***************************************>>>>





// ****************************dashboard download action******************


export const postDownloadCsvAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let csvListData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        csvListData = await postDownloadCsvApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CSV_DOWNLOAD,
            csvListData,
        });
        return csvListData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
// Dashboard second screen Downlaod Csv reports actions++++++++++=======================

export const postPerformanceReportDownloadCsvAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let csvPerformanceReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        csvPerformanceReport = await postPerformanceReportDownloadCsvApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_CSV_DOWNLOAD_PERFORMANCE_REPORTS,
            csvPerformanceReport,
        });
        return csvPerformanceReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postCreativeReportDownloadCsvAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let csvCreativeReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        csvCreativeReport = await postCreativeReportDownloadCsvApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_CSV_DOWNLOAD_CREATIVE_REPORTS,
            csvCreativeReport,
        });
        return csvCreativeReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postGeoReportDownloadCsvAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let csvGeoReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        csvGeoReport = await postGeoReportDownloadCsvApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_CSV_DOWNLOAD_GEO_REPORTS,
            csvGeoReport,
        });
        return csvGeoReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postDomainReportDownloadCsvAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let csvDomainReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        csvDomainReport = await postDomainReportDownloadCsvApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_CSV_DOWNLOAD_DOMAIN_REPORTS,
            csvDomainReport,
        });
        return csvDomainReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postPerformanceReportAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let performanceReportData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        performanceReportData = await postPerformanceReportApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: POST_PERFORMANCE_REPORT,
            performanceReportData,
        });
        return performanceReportData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getMediaplanPdfAction = ({ tenantId, authToken, userId, mediaplanName }) => async dispatch => {
    let pdfDownloadMediaplan = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pdfDownloadMediaplan = await getMediaplanPdfApi({ tenantId, authToken, userId, mediaplanName });
        dispatch({
            type: GET_MEDIA_PLAN_DOWNLOAD,
            pdfDownloadMediaplan,
        });
        return pdfDownloadMediaplan;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};





// ++++++++++++++++++++++++++++++++ PAYMENT SCREEEN ACTION STARTS HERE++++++++++++++++++++++++++++++++++




export const getAddPaymentRedirect = (amount, mode, tenantId, userId, authToken, preSignInState) => async dispatch => {
    try {
        let addPaymentData = ''
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addPaymentData = await getAddPaymentRedirectUrl(amount, mode, tenantId, userId, authToken, preSignInState);
        return addPaymentData
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}



export const getBillingHistoryAction = (tenantId, authToken, userId) => async dispatch => {
    let historyList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        historyList = await getBillingHistoryData({ tenantId, authToken, userId });
        dispatch({
            type: HISTORY_LIST,
            historyList,
        });
        return historyList;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getTenantDetailsAction = (tenantId, authToken, userId) => async dispatch => {
    let invoiceTenantList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        invoiceTenantList = await getTenantDetailsApi({ tenantId, authToken, userId });
        dispatch({
            type: INVOICE_TENANT_LIST,
            invoiceTenantList,
        });
        return invoiceTenantList;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const editInvoiceTenantAction = (tenantId, authToken, userId, payload) => async dispatch => {
    let editInvoiceTenantlist = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editInvoiceTenantlist = await editTenantDetailsApi(tenantId, authToken, userId, payload);
        dispatch({
            type: EDIT_INVOICE_TENANT_LIST,
            editInvoiceTenantlist,
        });
        return editInvoiceTenantlist;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const getInvoiceDetailsListAction = (tenantId, authToken, userId) => async dispatch => {
    let invoiceDetailsList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        invoiceDetailsList = await getInvoiceDetailsApi({ tenantId, authToken, userId });
        dispatch({
            type: INVOICE_DETAILS_LIST,
            invoiceDetailsList,
        });
        return invoiceDetailsList;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const getTentitiveInvoiceDetailsAction = (tenantId, authToken, userId) => async dispatch => {
    let tentitiveInvoiceDetails = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tentitiveInvoiceDetails = await getTentitiveInvoiceApi({ tenantId, authToken, userId });
        dispatch({
            type: TENTITIVE_INVOICE_LIST,
            tentitiveInvoiceDetails,
        });
        return tentitiveInvoiceDetails;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};











// ++++++++++++++++++++++++++++++++ PAYMENT SCREEEN ACTION ENDS HERE++++++++++++++++++++++++++++++++++




//  ================ MEDIA PLAN PDF CHECK STATUS ====================================



export const getStatusPdfCheckAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let advertiserList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        advertiserList = await getStatusPdfCheckApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_STATUS_PDF,
            advertiserList,
        });
        return advertiserList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ===== google login =========
export const getGoogleGenrateUrlAction = ({ tenantId, authToken, userId, callbackUrl }) => async dispatch => {
    let getGoogleUrl = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getGoogleUrl = await getGoogleUrlApi({ tenantId, authToken, userId, callbackUrl });
        dispatch({
            type: GENRATE_GOOGLE_URL,
            getGoogleUrl,
        });
        return getGoogleUrl;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const postGoogleVerificationAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let googleVerification = {};
    try {
        // console.log("UUUUUUUUUUUUUUUUUUUUU");
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        googleVerification = await postGoogleVerificationApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_GOOGLE_VERIFICATION_URL,
            googleVerification,
        });
        return googleVerification;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const postGoogleAccountsAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let googleAccounts = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        googleAccounts = await postGoogleAccountApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_GOOGLE_ACCOUNTS,
            googleAccounts,
        });
        return googleAccounts;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postDv360AccountsAction = ({ tenantId, userId, authToken, payload }) => async dispatch => {
    let dv360Accounts = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dv360Accounts = await postDv360AccountApi({ tenantId, userId, authToken, payload });
        dispatch({
            type: POST_DV360_ACCOUNTS,
            dv360Accounts,
        });
        return dv360Accounts;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postGoogleCampaignAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let googleCampaigns = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        googleCampaigns = await postCampaignApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CAMPAIGN,
            googleCampaigns,
        });
        return googleCampaigns;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postSyncCampaignAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let googleSyncCampaigns = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        googleSyncCampaigns = await postSyncCampaignApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_SYNC_CAMPAIGN,
            googleSyncCampaigns,
        });
        return googleSyncCampaigns;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postSyncCampaignDV360Action = ({ tenantId, userId, authToken, payload }) => async dispatch => {
    let DV360SyncCampaigns = {};
    console.log(tenantId, userId, authToken, "Action");

    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        DV360SyncCampaigns = await postSyncCampaignDV360Api({ tenantId, userId, authToken, payload});
        dispatch({
            type: POST_SYNC_CAMPAIGN_DV_360,
            DV360SyncCampaigns,
        });
        return DV360SyncCampaigns;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



// ========================================   DATA CONFIG  ==================================

export const getListDspPlatformAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let dspLsitAllData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dspLsitAllData = await getListDspPlatformApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_DSP_LIST_ALL_DATA,
            dspLsitAllData,
        });
        return dspLsitAllData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const postDspPlatfornCreationAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {

    let addDspPlatform = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addDspPlatform = await postDspPlatfornCreationApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CREATE_DSP_PLATFORM,
            addDspPlatform,
        });
        return addDspPlatform;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const getListByIdDspPlatformAction = ({ tenantId, authToken, userId, platformId }) => async dispatch => {
    let listByIdDataDspPlatform = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listByIdDataDspPlatform = await getListByIdDspPlatformApi({ tenantId, authToken, userId , platformId});
        dispatch({
            type: GET_LIST_BY_ID_DSP_PLATFORM,
            listByIdDataDspPlatform,
        });
        return listByIdDataDspPlatform;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const postDspPlatfornUpdateAction = ({ tenantId, authToken, userId, payload, platformId }) => async dispatch => {

    let updateDspPlatform = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateDspPlatform = await postDspPlatfornUpdateApi({ tenantId, authToken, userId, payload , platformId});
        dispatch({
            type: POST_UPDATE_DSP_PLATFORM,
            updateDspPlatform,
        });
        return updateDspPlatform;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const getVariableListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let variableListAllData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        variableListAllData = await getVariableListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_VARIABLE_LIST_ALL_DATA,
            variableListAllData,
        });
        return variableListAllData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const postVariableCreationAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {

    let VariableCreationData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        VariableCreationData = await postVariableCreationApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_CREATE_VARIABLE,
            VariableCreationData,
        });
        return VariableCreationData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getListByIdVariableAction = ({ tenantId, authToken, userId, platformId }) => async dispatch => {
    let listByIdDataVariable = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listByIdDataVariable = await getListByIdVariableApi({ tenantId, authToken, userId , platformId});
        dispatch({
            type: GET_LIST_BY_ID_VARIABLE,
            listByIdDataVariable,
        });
        return listByIdDataVariable;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const postVariableUpdateAction = ({ tenantId, authToken, userId, payload, platformId }) => async dispatch => {

    let updateVariableData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateVariableData = await postVariableUpdateApi({ tenantId, authToken, userId, payload , platformId});
        dispatch({
            type: POST_UPDATE_VARIABLE,
            updateVariableData,
        });
        return updateVariableData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ========================================   DATA CONFIG  ENDS HERE  ==================================





// AUDIENCE DOWNLAOD CSV ACTION 



export const audienceHierarchyDownloadCSVAction = (audienceHierarchyDownloadCSVObj) => async dispatch => {
    let audienceHierarchyDownloadCSV = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        audienceHierarchyDownloadCSV = await audienceHierarchyDownloadCSVApi(audienceHierarchyDownloadCSVObj);
        return audienceHierarchyDownloadCSV;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};









export const getUserInformationAction = ({ tenantId }) => async dispatch => {
    let getUserInfo = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getUserInfo = await getGoogleUserInformationApi({ tenantId });
        dispatch({
            type: GET_USER_DETAILS,
            getUserInfo,
        });
        return getUserInfo;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getGoogleInfomoAction = ({ tenantId,authToken,userId,params={} }) => async dispatch => {
    let googleInfomoInfo = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        googleInfomoInfo = await getInfomoSeatGoogleApi({ tenantId,authToken,userId,params });
        
        return googleInfomoInfo;

    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getFacebookInfomoAction = ({ tenantId,authToken,userId,params={} }) => async dispatch => {
    let fbInfomoInfo = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        fbInfomoInfo = await getInfomoSeatFacebookApi({ tenantId,authToken,userId,params });
        
        return fbInfomoInfo;

    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ======== facebook signin api implementation ===========
export const getFacebookUserInfoAction = ({ accessToken }) => async dispatch => {
    let getFacebookUserInfo = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getFacebookUserInfo = await getFbUserInfoApi({ accessToken });
        dispatch({
            type: GET_FACEBOOK_USER_ACCOUNTS,
            getFacebookUserInfo,
        });
        return getFacebookUserInfo;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getFacebookUserAccountAction = ({ tenantId , authToken, userId, params, payload }) => async dispatch => {
    let getFacebookAccountInfo = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });

        getFacebookAccountInfo = await getFbUserAccountsApi({ tenantId , authToken, userId, params, payload });
       
        return getFacebookAccountInfo;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getFacebookUserRedirectUrlAction = ({ tenantId , authToken, userId, params }) => async dispatch => {
    let getFacebookRedirectInfo = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });

        getFacebookRedirectInfo = await getFbUserRedirectUrlApi({ tenantId , authToken, userId, params });
       
        return getFacebookRedirectInfo;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getFacebookRefetchAction = ({ tenantId, userId, authToken, accountId }) => async dispatch => {
    let getResyncList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getResyncList = await getFbResyncApi({ tenantId, userId, authToken, accountId });
        dispatch({
            type: GET_FACEBOOK_REFETCH_CAMPAIGN,
            getResyncList,
        });
        return getResyncList;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ========== sync campaign ===========
export const syncAccCampaignAction = ({ tenantId, userId, payload }) => async dispatch => {
    let getCampaignDetails = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCampaignDetails = await campaignDetailsApi({ tenantId, userId, payload });
        dispatch({
            type: GET_CAMPAIGN,
            getCampaignDetails,
        });
        return getCampaignDetails;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const facebookSyncCampaignAction = ({ tenantId, userId, payload }) => async dispatch => {
    let syncCampaign = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        syncCampaign = await facebookSyncCampaignApi({ tenantId, userId, payload });
        dispatch({
            type: FACEBOOK_SYNC_CAMPAIGN,
            syncCampaign,
        });
        return syncCampaign;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ========== dv360 signin url =========
export const getDv360UrlAction = ({ callbackUrl }) => async dispatch => {
    let getDV360Url = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getDV360Url = await getDv360UrlApi({ callbackUrl });
        dispatch({
            type: GET_DV360_URL,
            getDV360Url,
        });
        return getDV360Url;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postDV360VerificationAction = ({ tenantId, userId, authToken, payload }) => async dispatch => {
    let dv360Verification = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dv360Verification = await postDV360VerificationApi({  tenantId, userId, authToken, payload });
        dispatch({
            type: POST_DV360_VERIFICATION_URL,
            dv360Verification,
        });
        return dv360Verification;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postDv360CampaignAction = ({tenantId, userId, authToken, payload }) => async dispatch => {
    console.log(tenantId,"action")
    let dv360Campaigns = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dv360Campaigns = await postDv360CampaignApi({ tenantId, userId, authToken,  payload });
        dispatch({
            type: POST_DV360_CAMPAIGN,
            dv360Campaigns,
        });
        return dv360Campaigns;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ========== gam signin url =========
export const getGamUrlAction = () => async dispatch => {
    let getGamUrl = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getGamUrl = await getGamUrlApi();
        dispatch({
            type: GET_GAM_URL,
            getGamUrl,
        });
        return getGamUrl;
    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postGamVerificationAction = ({ payload }) => async dispatch => {
    let gamVerification = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        gamVerification = await postGamVerificationApi({ payload });
        dispatch({
            type: POST_GAM_VERIFICATION_URL,
            gamVerification,
        });
        return gamVerification;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// postGamCampaignApi,
// 
export const postGamCampaignAction = ({ payload }) => async dispatch => {
    let gamCampaignList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        gamCampaignList = await postGamCampaignApi({ payload });
        dispatch({
            type: POST_GAM_CAMPAIGN_LIST,
            gamCampaignList,
        });
        return gamCampaignList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postGamSyncCampaignAction = ({ payload }) => async dispatch => {
    let gamSyncCampaign = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        gamSyncCampaign = await postGamSyncCampaignApi({ payload });
        dispatch({
            type: POST_GAM_SYNC_CAMPAIGN,
            gamSyncCampaign,
        });
        return gamSyncCampaign;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const tiktokLocationAction = ({ tenantId, payload }) => async dispatch => {
    let tiktokLocation = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tiktokLocation = await postTiktokLocationApi({ tenantId, payload });
        dispatch({
            type: POST_TIKTOK_LOCATION,
            tiktokLocation,
        });
        return tiktokLocation;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// GET_GEO_LOCATION

export const getGeoLocationAction = ({tenantId, authToken, userId, query}) => async dispatch => {
    let getGeoLocation = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getGeoLocation = await getGeoLocationApi({tenantId, authToken, userId, query});
        dispatch({
            type: GET_GEO_LOCATION,
            getGeoLocation,
        });
        return getGeoLocation;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getGeoLocationFacebookAction = ({tenantId, authToken, userId, query}) => async dispatch => {
    let getGeoLocation = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getGeoLocation = await getGeoLocationApiFacebook({tenantId, authToken, userId, query});
        dispatch({
            type: GET_GEO_LOCATION,
            getGeoLocation,
        });
        return getGeoLocation;

    } catch (err) {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    } finally {
        if(query.includes('zip') && query.includes('fb/geo?search')){
            dispatch({
                type: END_AJAX_CALL,
                showSpinner: false,
            });
        }
    }
};

export const getFacebookAccountsAction = ({tenantId, authToken, userId}) => async dispatch => {
    let FacebookAccountsID = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        FacebookAccountsID = await getFacebookAccountApi({tenantId, authToken, userId});
        dispatch({
            type: GET_FACEBOOK_ACCOUNTS,
            FacebookAccountsID,
        });
        return FacebookAccountsID;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const checkDuplicateNameAction = ({tenantId, authToken, userId, value}) => async dispatch => {
    let checkDuplicateName = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        checkDuplicateName = await checkDuplicateNameApi({tenantId, authToken, userId, value});
        // dispatch({
        //     type: GET_DUPLICATE_NAME,
        //     checkDuplicateName,
        // });
        return checkDuplicateName;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



export const checkDuplicateGoogleCampaignAction = (tenantId, authToken, userId, customerId) => async dispatch => {
    let checkDuplicateGooleCampaign = {};
    try {
        console.log("customerId",customerId)
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        checkDuplicateGooleCampaign = await checkDuplicateGoogleCampaignApi(tenantId, authToken, userId, customerId);
        // dispatch({
        //     type: GET_DUPLICATE_CAMPAIGN_NAME,
        //     checkDuplicateCampaignName,
        // });
        return checkDuplicateGooleCampaign;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteAccountsAction = (tenantId, authToken, userId, accountId) => async dispatch => {
    let deleteGoogleAccount = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteGoogleAccount = await deleteAccountsApi( tenantId, authToken, userId, accountId );
        return deleteGoogleAccount;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampainNamesDV360Action = (tenantId, authToken, userId, accountId) => async dispatch => {
    let campaignDV360Names = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignDV360Names = await DV360CampaignNamesApi({ tenantId, authToken, userId, accountId });
        dispatch({
            type: GET_LIST_DV_360,
            campaignDV360Names,
        });
        return campaignDV360Names
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getGenrateAccountIdDV360Action = ({tenantId, authToken, userId, payload}) => async dispatch => {
    console.log(payload,"action")

    let accountIdDv = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        accountIdDv = await getGenrateAccountIdDV360Api({ tenantId, authToken, userId, payload });
        dispatch({
            type: GET_ACCOUNT_ID_DV,
            accountIdDv,
        });
        return accountIdDv
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getCampaignCustomAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let advertiserList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        advertiserList = await getCampaignCustomApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_CAMPAIGN_CUSTOM,
            advertiserList,
        });
        return advertiserList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// World MAP

export const postWorldMapAction = ({tenantId, authToken, userId, payload}) => async dispatch => {
    let worldMapData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        worldMapData = await postWorldMapApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: POST_WORLD_MAP_DATA,
            worldMapData,
        });
        return worldMapData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const postWorldGeoCampaignAction = ({ tenantId, authToken, userId, payload, campaignId }) => async dispatch => {
    let perticualarWorldMapData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        perticualarWorldMapData = await postWorldGeoCampaignApi({ tenantId, authToken, userId, payload, campaignId });
        dispatch({
            type: PERTICULAR_WORLD_MAP_DATA,
            perticualarWorldMapData,
        });
        return perticualarWorldMapData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getCurrencyListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let currencyList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        currencyList = await getCurrencyListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_CURRENCY_LIST,
            currencyList,
        });
        return currencyList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};