export const PERMISSION = {
  // DASHBOARD: {
  //   DASHBOARDREPORT: {
  //     ATDREPORT: {
  //       VIEW: "ATD_NEW_TEST-DASHBOARD-ALL RIGHTS-ATD::DASHBOARD"
  //     }
  //   }
  // },
  DASHBOARD: {
    DASHBOARD: {
      VIEW: "ATD_NEW_TEST-DASHBOARD-ALL RIGHTS-ATD::DASHBOARD",
      // ADD: "ATD_NEW_TEST-RO-ALL RIGHTS-ATD::RO",
      // EDIT: "ATD_NEW_TEST-RO-ALL RIGHTS-ATD::RO",
    }
  },
  MEDIAPLAN: {
    MEDIAPLAN: {
      VIEW: "ATD_NEW_TEST-MEDIAPLAN-ALL RIGHTS-ATD::MEDIAPLAN",
      ADD: "ATD_NEW_TEST-MEDIAPLAN-ALL RIGHTS-ATD::MEDIAPLAN",
      DOWNLAOD: "ATD_NEW_TEST-MEDIAPLAN-ALL RIGHTS-ATD::MEDIAPLAN",
    }
  },

  ADMINMARGIN: {
    ADMINMARGIN: {
      VIEW: "ATD_NEW_TEST-ADMINMARGIN-ALL RIGHTS-ATD:ADMINMARGIN",
      ADD: "ATD_NEW_TEST-ADMINMARGIN-ALL RIGHTS-ATD:ADMINMARGIN",
      EDIT: "ATD_NEW_TEST-ADMINMARGIN-ALL RIGHTS-ATD:ADMINMARGIN",
    }
  },

  MARGIN: {
    MARGIN: {
      VIEW: "ATD_NEW_TEST-MARGIN-ALL RIGHTS-ATD::MARGIN",
      ADD: "ATD_NEW_TEST-MARGIN-ALL RIGHTS-ATD::MARGIN",
      EDIT: "ATD_NEW_TEST-MARGIN-ALL RIGHTS-ATD::MARGIN",
    }
  },

  RO: {
    RO: {
      VIEW: "ATD_NEW_TEST-RO-ALL RIGHTS-ATD::RO",
      ADD: "ATD_NEW_TEST-RO-ALL RIGHTS-ATD::RO",
      EDIT: "ATD_NEW_TEST-RO-ALL RIGHTS-ATD::RO",
    }
  },


  DATACONFIG: {
    DATACONFIG: {
      VIEW: "ATD_NEW_TEST-DATA CONFIG-ALL RIGHTS-ATD::DATACONFIG",
    }
  },

  UNMAPPEDCAMPAIGN: {
    UNMAPPEDCAMPAIGN: {
      VIEW: "ATD_NEW_TEST-UNMAPPEDCAMPAIGN-ALL RIGHTS-ATD::UNMAPPED",
      ADD_RO: "ATD_NEW_TEST-UNMAPPEDCAMPAIGN-ALL RIGHTS-ATD::UNMAPPED",
      MAP_RO: "ATD_NEW_TEST-UNMAPPEDCAMPAIGN-ALL RIGHTS-ATD::UNMAPPED",
    }
  },

  OPTIMIZER: {
    OPTIMIZER: {
      VIEW: "ATD_NEW_TEST-RECOMMENDATIONS-ALL RIGHTS-ATD::RECOMMENDATIONS",
      // VIEW_RECOMMENDATION: "ATD_NEW_TEST-RECOMMENDATIONS-VIEW-LOGIN :: OPTIMIZER :: RECOMMENDATION :: VIEW RECOMMENDATION",
      // VIEW_HISTORY: "ATD_NEW_TEST-HISTORY-VIEW-LOGIN :: OPTIMIZER :: HISTORY :: VIEW HISTORY",
      // VIEW_RECOMMENDATIONS: "ATD_NEW_TEST-RECOMMENDATIONS-VIEW-LOGIN :: OPTIMIZER :: RECOMMENDATION :: CURRENTRECODETAILS :: VIEW CURRENTRECODETAILS",
      // VIEW_CURRENT_RECOMMENDATION: "ATD_NEW_TEST-RECOMMENDATIONS-VIEW-LOGIN :: OPTIMIZER :: RECOMMENDATION :: CURRENTRECODETAILS :: CURRENT RECO :: VIEW CURRENT RECO",



    }
  },


  CAMPAIGN: {
    CAMPAIGN: {
      VIEW: "ATD_NEW_TEST-CAMPAIGN-ALL RIGHTS-ATD::CAMPAIGN",
      ADD: "ATD_NEW_TEST-CAMPAIGN-ALL RIGHTS-ATD::CAMPAIGN",
      EDIT: "ATD_NEW_TEST-CAMPAIGN-ALL RIGHTS-ATD::CAMPAIGN",
    },

  },



  ADMIN: {
    TENANT: {
      VIEW: "ADMIN-TENANT-VIEW-LOGIN :: ADMIN :: TENANT",
      ADD: "ADMIN-TENANT-ADD-LOGIN :: ADMIN :: TENANT :: ADD TENANT",
      EDIT: "ADMIN-TENANT-EDIT-LOGIN :: ADMIN :: TENANT :: EDIT TENANT",
      DELETE: "ADMIN-TENANT-DELETE-LOGIN :: ADMIN :: TENANT"
    },
    MGRTENANT: {
      VIEW: "ADMIN-MGRTENANT-VIEW-LOGIN :: ADMIN ::VIEW MGR TENANT",
      // ADD: "ADMIN-TENANT-ADD-LOGIN :: ADMIN :: TENANT :: ADD TENANT",
      // EDIT: "ADMIN-TENANT-EDIT-LOGIN :: ADMIN :: TENANT :: EDIT TENANT",
      ADD: "ADMIN-MGRTENANT-ADD-LOGIN :: ADMIN :: MGR TENANT :: ADD MGR TENANT",
      EDIT: "ADMIN-MGRTENANT-EDIT-LOGIN :: ADMIN :: MGR TENANT :: EDIT MGR TENANT",
      // DELETE: "ADMIN-TENANT-DELETE-LOGIN :: ADMIN :: TENANT",

    },
    ACCOUNT: {
      VIEW: "ADMIN-ACCOUNT-VIEW-LOGIN :: ADMIN :: ACCOUNT",
      ADD: "ADMIN-ACCOUNT-ADD-LOGIN :: ADMIN :: ACCOUNT :: ADD ACCOUNT",
      EDIT: "ADMIN-ACCOUNT-EDIT-LOGIN :: ADMIN :: ACCOUNT :: EDIT ACCOUNT",
      DELETE: "ADMIN-ACCOUNT-DELETE-LOGIN :: ADMIN :: ACCOUNT"
    },
    FEATURES: {
      VIEW: "ADMIN-FEATURES-VIEW-LOGIN :: ADMIN :: FEATURES",
      ADD: "ADMIN-FEATURES-ADD-LOGIN :: ADMIN :: FEATURES",
      EDIT: "ADMIN-FEATURES-EDIT-LOGIN :: ADMIN :: FEATURES",
      DELETE: "ADMIN-FEATURES-DELETE-LOGIN :: ADMIN :: FEATURES"
    },
    USERS: {
      VIEW: "ADMIN-USERS-VIEW-LOGIN :: ADMIN :: USER",
      ADD: "ADMIN-USERS-ADD-LOGIN :: ADMIN :: USER :: ADD USER",
      EDIT: "ADMIN-USERS-EDIT-LOGIN :: ADMIN :: USER :: EDIT USER",
      DELETE: "ADMIN-USERS-DELETE-LOGIN :: ADMIN :: USER"
    },
    DEMANDPARTNER: {
      VIEW: "ADMIN-DEMAND PARTNER-VIEW-LOGIN :: ADMIN :: DEMAND PARTNER",
      ADD: "ADMIN-DEMAND PARTNER-ADD-LOGIN :: ADMIN :: DEMAND PARTNER :: ADMIN :: ADD DEMAND PARTNER",
      EDIT: "ADMIN-DEMAND PARTNER-EDIT-LOGIN :: ADMIN :: DEMAND PARTNER :: ADMIN :: EDIT DEMAND PARTNER",
      DELETE: "ADMIN-DEMAND PARTNER-DELETE-LOGIN :: ADMIN :: DEMAND PARTNER"
    },
    SUPPLYPARTNER: {
      VIEW: "ADMIN-SUPPLY PARTNER-VIEW-LOGIN :: ADMIN :: SUPPLY PARTNER",
      ADD: "ADMIN-SUPPLY PARTNER-ADD-LOGIN :: ADMIN :: SUPPLY PARTNER :: ADD SUPPLY PARTNER",
      EDIT: "ADMIN-SUPPLY PARTNER-EDIT-LOGIN :: ADMIN :: SUPPLY PARTNER :: EDIT SUPPLY PARTNER",
      DELETE: "ADMIN-SUPPLY PARTNER-DELETE-LOGIN :: ADMIN :: SUPPLY PARTNER"
    },
    VARIABLE: {
      VIEW: "ADMIN-VARIABLE-VIEW-LOGIN :: ADMIN :: VARIABLE",
      ADD: "ADMIN-VARIABLE-ADD-LOGIN :: ADMIN :: VARIABLE :: ADD VARIABLE",
      EDIT: "ADMIN-VARIABLE-EDIT-LOGIN :: ADMIN :: VARIABLE :: EDIT VARIABLE",
      DELETE: "ADMIN-VARIABLE-DELETE-LOGIN :: ADMIN :: VARIABLE"
    },
    ROLES: {
      VIEW: "ADMIN-ROLES-VIEW-LOGIN :: ADMIN :: ROLES",
      ADD: "ADMIN-ROLES-ADD-LOGIN :: ADMIN :: ROLES :: ADD ROLES",
      EDIT: "ADMIN-ROLES-EDIT-LOGIN :: ADMIN :: ROLE :: EDIT ROLE",
      DELETE: "ADMIN-ROLES-DELETE-LOGIN :: ADMIN :: ROLES"
    },
    AGREEMENT: {
      VIEW: "ADMIN-AGREEMENT-VIEW-LOGIN :: ADMIN :: AGREEMENT",
      ADD: "ADMIN-AGREEMENT-ADD-LOGIN :: ADMIN :: AGREEMENT :: ADD AGREEMENT",
      EDIT: "ADMIN-AGREEMENT-EDIT-LOGIN :: ADMIN :: AGREEMENT :: EDIT AGREEMENT",
    },
    SUPPLYPATH: {
      VIEW: "ADMIN-SUPPLY PATH-VIEW-LOGIN :: ADMIN :: SUPPLY PATH",
      EDIT: "ADMIN-SUPPLY PATH-EDIT-LOGIN :: ADMIN :: SUPPLY PATH :: EDIT SUPPLY PATH",
    },
    ADVERTISER: {
      VIEW: "ATD_NEW_TEST-ADVERTISER-ALL RIGHTS-ATD :: ADMIN :: ADVERTISER",
      ADD: "ATD_NEW_TEST-ADVERTISER-ALL RIGHTS-ATD :: ADMIN :: ADVERTISER",
      EDIT: "ATD_NEW_TEST-ADVERTISER-ALL RIGHTS-ATD :: ADMIN :: ADVERTISER",
    },

  },



  AUDIENCE: {
    AUDIENCEHIERARCHY: {
      VIEW: "ATD_NEW_TEST-AUDIENCE HIERARCHY-ALL RIGHTS-ATD :: AUDIENCE :: AUDIENCE HIERARCHY",
      EDIT: "ATD_NEW_TEST-AUDIENCE HIERARCHY-ALL RIGHTS-ATD :: AUDIENCE :: AUDIENCE HIERARCHY"
    },
    HIERARCHYMANAGER: {
      VIEW: "ATD_NEW_TEST-HIERARCHY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: HIERARCHY MANAGER",
      ADD: "ATD_NEW_TEST-HIERARCHY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: HIERARCHY MANAGER",
      EDIT: "ATD_NEW_TEST-HIERARCHY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: HIERARCHY MANAGER",
      DELETE: "ATD_NEW_TEST-HIERARCHY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: HIERARCHY MANAGER"
    },
    APR: {
      VIEW: "AUDIENCE-APR-VIEW-LOGIN :: AUDIENCE :: APR",
      ADD: "AUDIENCE-APR-ADD-LOGIN AUDIENCE :: APR AUDIENCE :: CREATE NEW APR",
      EDIT: "AUDIENCE-APR-EDIT-LOGIN AUDIENCE :: APR AUDIENCE :: EDIT  APR",
      DOWNLOAD: "AUDIENCE-APR-DOWNLOAD-LOGIN :: AUDIENCE :: APR"
    },
    TAXONOMYMANAGER: {
      VIEW: "ATD_NEW_TEST-TAXONOMY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: TAXONOMY MANAGER",
      ADD: "ATD_NEW_TEST-TAXONOMY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: TAXONOMY MANAGER",
      EDIT: "ATD_NEW_TEST-TAXONOMY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: TAXONOMY MANAGER",
      DELETE: "ATD_NEW_TEST-TAXONOMY MANAGER-ALL RIGHTS-ATD :: AUDIENCE :: TAXONOMY MANAGER"
    },
    DATACAPTURE: {
      VIEW: "ATD_NEW_TEST-DATA CAPTURE-ALL RIGHTS-ATD :: AUDIENCE :: DATA CAPTURE",
      ADD: "ATD_NEW_TEST-DATA CAPTURE-ALL RIGHTS-ATD :: AUDIENCE :: DATA CAPTURE",
      EDIT: "ATD_NEW_TEST-DATA CAPTURE-ALL RIGHTS-ATD :: AUDIENCE :: DATA CAPTURE",
      DELETE: "ATD_NEW_TEST-DATA CAPTURE-ALL RIGHTS-ATD :: AUDIENCE :: DATA CAPTURE",
      DOWNLOAD: "ATD_NEW_TEST-DATA CAPTURE-ALL RIGHTS-ATD :: AUDIENCE :: DATA CAPTURE"
    }
  }










}


export const MODULE_PERMISSIONS = {
  // DASHBOARD: [
  //   PERMISSION.DASHBOARD.DASHBOARDREPORT.ATDREPORT.VIEW,
  // ],
  DASHBOARD: [
    PERMISSION.DASHBOARD.DASHBOARD.VIEW
  ],
  MEDIAPLAN: [
    PERMISSION.MEDIAPLAN.MEDIAPLAN.VIEW
  ],
  ADMINMARGIN: [
    PERMISSION.ADMINMARGIN.ADMINMARGIN.VIEW
  ],

  MARGIN: [
    PERMISSION.MARGIN.MARGIN.VIEW
  ],

  RO: [
    PERMISSION.RO.RO.VIEW
  ],

  DATACONFIG: [
    PERMISSION.DATACONFIG.DATACONFIG.VIEW
  ],

  UNMAPPEDCAMPAIGN: [
    PERMISSION.UNMAPPEDCAMPAIGN.UNMAPPEDCAMPAIGN.VIEW
  ],

  OPTIMIZER: [
    PERMISSION.OPTIMIZER.OPTIMIZER.VIEW
  ],


  CAMPAIGN: [
    PERMISSION.CAMPAIGN.CAMPAIGN.VIEW
  ],





  ADMIN: [
    PERMISSION.ADMIN.TENANT.ADD,
    PERMISSION.ADMIN.ACCOUNT.VIEW,
    PERMISSION.ADMIN.FEATURES.VIEW,
    PERMISSION.ADMIN.USERS.VIEW,
    PERMISSION.ADMIN.DEMANDPARTNER.VIEW,
    PERMISSION.ADMIN.SUPPLYPARTNER.VIEW,
    PERMISSION.ADMIN.VARIABLE.VIEW,
    PERMISSION.ADMIN.ROLES.VIEW,
    PERMISSION.ADMIN.AGREEMENT.VIEW,
    PERMISSION.ADMIN.SUPPLYPATH.VIEW,
    PERMISSION.ADMIN.ADVERTISER.VIEW

  ],

  ADMIN_TENANT: [PERMISSION.ADMIN.TENANT.VIEW],
  ADMIN_ACCOUNT: [PERMISSION.ADMIN.ACCOUNT.VIEW],
  ADMIN_FEATURES: [PERMISSION.ADMIN.FEATURES.VIEW],
  ADMIN_USERS: [PERMISSION.ADMIN.USERS.VIEW],
  ADMIN_DEMANDPARTNER: [PERMISSION.ADMIN.DEMANDPARTNER.VIEW],
  ADMIN_SUPPLYPARTNER: [PERMISSION.ADMIN.SUPPLYPARTNER.VIEW],
  ADMIN_VARIABLE: [PERMISSION.ADMIN.VARIABLE.VIEW],
  ADMIN_ROLES: [PERMISSION.ADMIN.ROLES.VIEW],
  ADMIN_AGREEMENT: [PERMISSION.ADMIN.AGREEMENT.VIEW],
  ADMIN_SUPPLYPATH: [PERMISSION.ADMIN.SUPPLYPATH.VIEW],
  ADMIN_ADVERTISER: [PERMISSION.ADMIN.ADVERTISER.VIEW],




  AUDIENCE: [
    PERMISSION.AUDIENCE.AUDIENCEHIERARCHY.VIEW,
    PERMISSION.AUDIENCE.HIERARCHYMANAGER.VIEW,
    PERMISSION.AUDIENCE.APR.VIEW,
    PERMISSION.AUDIENCE.TAXONOMYMANAGER.VIEW,
    PERMISSION.AUDIENCE.DATACAPTURE.VIEW,
  ],

  AUDIENCE_HIERARCHY: [PERMISSION.AUDIENCE.AUDIENCEHIERARCHY.VIEW],
  AUDIENCE_HIERARCHYMANAGER: [PERMISSION.AUDIENCE.HIERARCHYMANAGER.VIEW],
  AUDIENCE_APR: [PERMISSION.AUDIENCE.APR.VIEW],
  AUDIENCE_TAXONOMYMANAGER: [PERMISSION.AUDIENCE.TAXONOMYMANAGER.VIEW],
  AUDIENCE_DATACAPTURE: [PERMISSION.AUDIENCE.DATACAPTURE.VIEW],







}